.toolbar{
    height: 10.71% !important;
}

.toolbarCuestionario{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    background-image: 
    linear-gradient(rgba(69,34,95, 0.85), rgba(215,66,134, 0.5)), 
    url(../../public/assets/images/img_cuestionario.png); 
    background-size: cover; 
    background-position: top; 
    background-repeat: no-repeat;
    height: 15.7svh;
}

.contenedor {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-bottom: 15%;
    justify-content: space-evenly;
}
.contenedorCodigo{
    height: 100%;
    width: auto;
    aspect-ratio: 1;
    border-radius: 51px;
    position: relative;
    padding: 5% 10% 5% 10%;
    font-family: "Source Sans Pro";
    font-size: 17.77vh;
    font-style: normal;
    font-weight: 700;
    line-height: 28.14%;
    text-transform: uppercase;
    color: #512362;
}

.imagen {
    height: 100%;  
} 
.contenedorScroll{
    left: 0;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    transition: transform 0.3s ease-in-out; 
    overflow: hidden;
}
.scroll{
    flex: 0 0 auto;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center ;
    max-width: 100%;
    font-size: 20px;
    max-height: 100%;
    line-height: 120%;
}
.contenedorOpciones {
    width: 40svh;
    height: 8.3svh;
    top: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2%;
    margin: 2%;
    background: transparent;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.contenedorOpciones button:nth-child(n){
    border: none;
    box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
    height: 100%;
}

.contenedorDescripciones {
    margin-top: 2vh;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    width: 37vh;
    height: 5%;
}

.contenedorDescripciones img{
    height: 54.77%;
}
.imagenOpciones {
    height: 100%;
    aspect-ratio: 1/1;
} 
.anterior, .siguiente {
    margin: 10px;
    padding: 10px 20px;
    font-size: 18px;
  }

.navegacionImagen{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40svh;
    margin-bottom: 3.52%;
}

.flechasPasar{
    opacity: 0.5;
}

.seleccionada{
    background: var(--color-Pink-Light) !important;
    width: 100%;
    border-radius: 15px;
    color: white;
    font-weight: 600;

}
.transparente{
    background: transparent !important;

}
.left{
    margin-right: 3%;

}
.right {
    margin-left: 3%;
}
.buttonDescription{
    position: absolute;
    top: 5.08%;
    right: 4.4%;
    transform: translateX(-50%);
    height: 6%;
    width: auto;
}

/*  estilos para los cuestionarios*/


.contenedorCuestionario{
    width: 100%;
    padding: 20.5%  8.2% 0 5.12%;
    font-family: 'Montserrat';
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pregunta{
    color: var(--color-purple-dark);
    font-size: 2.13vh;
    font-weight: 700;
    line-height: 155.556%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
}
.indexPregunta{
    font-weight: 700;
    width: 6.8%;
    text-align: center;
    color: var(--color-purple-dark);
}

.respuesta{
    width: 100%;
    height: 7.11vh;
    background: white;
    font-size: 1.89vh;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: -0.48px;
    border-radius: 15px;
    border: 2px solid var(--color-purple-dark);
    flex-direction: row;
    justify-content: start;
    padding: 4.44% 0 4.44% 4.44%;
    margin-bottom: 3.174%;
    z-index: 1;
}
.contenedorRespuestas, .respuesta, .opcionesLetras, .botonesVolver{
    align-items: center;  
    display: flex;
}
.contenedorRespuestas {
    flex-direction: column;
    font-style: normal;
    padding-left:6.8% ;
    width: 100%;
    font-family: 'Montserrat';
    color:  #404040;
}
.contenedorRespuestas div:last-child{
    margin-bottom: 3.86%;
}
.opcionesLetras{
    color: #D74286;
    background-color: white;
    text-align: center;
    font-size: 1.65vh;
    font-weight: 700;
    line-height: 142.857%;
    letter-spacing: -0.42px;
    border-radius: 50%;
    border: 2.3px solid #D74286 ;
    height: 3.79vh;
    width: 3.79vh;
    justify-content: center; 
    margin-right: 6.349%;
}
.seleccionadaCuestionario{
    background: #D74286 !important;
    border: 2px solid #C23777;
    color: white;

}
.botonesVolver{
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: 10px;
    margin: 4% 0 ;
}
.botonVolver{
    background: var(--color-degradado-vertical);
    height: 5.9vh;
    padding: 1.5px;
    width: 38.76%;
    border-radius: 15px;
    margin-right: 8.57%;

}
.butonBordes{
    background: white;
    height: 100%;
    width: 100%;
    border-radius: 15px;
}
.butonBordes span{
    background: var(--color-degradado-vertical);
    -webkit-background-clip: text; 
    background-clip: text; 
    color: transparent;
    font-size:1.9vh;
    font-weight: 600;
    line-height: 40px;
}

.modal{
    width: 88.2% !important;
    height: 51.66% !important;
    padding: 0 4.36%;
    text-align: center;
    color: #868189;
    font-family: 'Montserrat';
    font-size: 1.89svh;
    font-weight: 600;
    line-height:    150% ;
    text-transform: capitalize;
    position: relative;
}
.modal img:last-child{
    height: 54.128%;
    width: auto;
}
.tituloImagen{
    color: #404040;
    text-align: center;
    font-size: 2.84svh;
    line-height: 100%;
    text-transform: uppercase;
}

.back{
    position: absolute;
    top: 3.89%;
    left: 4.94%;
}
.finalizar{
    width: 30% !important;
    height: 7% !important;
}