#page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f5f5f5;
  }
  
  .contenedorIconoMatematico {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .icono {
    width: 150px;
    height: auto;
  }
  
  .buttonEmpezar {
    display: block;
    padding: 1rem 2rem;
    background-color: #ff4081;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .buttonEmpezar:hover {
    background-color: #e73370;
  }
  
  .toolbarCalculoMatematico {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #2196f3;
    color: white;
  }
  
  .toolbar_default {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f44336;
    color: white;
  }
  
  .tituloJuegos {
    font-size: 2rem;
    text-align: center;
  }
  
  .descripcionJuego {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .primeraPantalla {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .subMensaje {
    font-size: 1.5rem;
    color: #333;
    margin-top: 2rem;
  }
  
  .button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  
  .button#button_bordes_default {
    background-color: #ffc107;
    color: #333;
  }
  
  