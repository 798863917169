.area{
    height: max-content;
    margin-bottom: 5%;
    padding: 2%;
}

input{
    width: 100%;
    background-color: #D9D9D9;
    border: none;
    border-radius: 15px;
    padding: 2%;
}

.containerInput{
    padding: 1%;
}

.containerInput:focus-within p{
    font-size: 2vh;
    color: rgb(217, 217, 250);
}
.containerInput:focus-within input{
    background-color: rgb(217, 217, 300);
}
.containerInput:focus-within .area{
    background-color: rgb(217, 217, 300);
}

.headerCard{
    /* margin-bottom: -20px; */
    display: block;
    position: relative;
    -ms-flex-order: -1;
    order: -1;
    width: 100%;
    z-index: 10;
    background: var(--color-degradado-vertical);
}

.headerCard h1{
    transform: none;
    flex: 1;
}

.exit{
    width: 15%;
    height: auto;
    padding: 3%;
}

.modalActive .content{
    background-color: #FFFFFF;
    color: grey;
    padding: 2%;
    flex: 1;
    border-radius: 0px;
}

.modalActive{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 21003;
}

.modalDesactive{
    display: none;
}