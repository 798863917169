.toolbarSupercerebrosDice 
{
    --min-height:30vh;
}
.headerSupercerebrosDice
{
    margin-bottom: -17.7vh;
}
.fondo{
    background: url(../../public/assets/componentes/Juegos/fondoNeuronasGrises.png)
    left top -50%/ cover, white !important;
}

.supercerebrosDice {
    text-align: center;
    padding-top: 8.65svh;
}
.backSupercerebrosDice{
    margin-top: 0;
    margin-bottom: 16.5vh;
    --background: transparent;
    --color:white;
    --box-shadow: none;
}
.logoSupercerebrosDice{
    width: 18.25vh;
    height: 18.25vh;
   display: grid;
   grid-template-columns: repeat(2, 2fr);
}
.c1{
    width: 98%;
    height: 98%;
    border-top-left-radius: 45%;
    background: linear-gradient(180deg, var(--color-Pink-Light) 0%, #45225F 100%);
}
.c2{
    width: 98%;
    height: 98%;
    border-top-right-radius: 45%;
    background-color: var(--color-purple-dark);
}
.btnPlay{
    height: 100%;
}
.playIcon{
    font-size: 196px;
}

.cortina{
    background: transparent;
    width: 39svh;
    height: 63.3svh;
    position: absolute;
}
.tablero {
    display: flex;
    flex-direction: column;
    align-items: center;
} 

.fila {
    display: flex;
    justify-content: center;
    
}
.color {
    width: 18.3vh;
    height: 19.9vh;
    border-radius: 15px;
    cursor: pointer;
    margin: 0.6vh;
}

.rojo :hover {
    animation: undir  0.5s forwards;
}

@keyframes undir {
    0% {
        box-shadow: 0px -9.5px var(--color-Dark-red) inset;
        height: 19.9vh;
    }
    50%{
        box-shadow: none;
        height: calc(19.9svh - 9.5px);
    }
    100% {
        box-shadow: 0px -4.75px var(--color-Dark-red) inset;
        height: 19.9vh;
    }
}


.rojo {
    background-color: var(--color-red);
    box-shadow: 0px -9.5px var(--color-Dark-red) inset;
}

.verde {
    background-color: var(--color-green);
    box-shadow: 0px -9.5px var(--color-Dark-green) inset;
}

.azul {
    background-color:var(--color-light-blue);
    box-shadow: 0px -9.5px var(--color-Dark-blue) inset;
}

.amarillo {
    background-color: var(--color-yellow);
    box-shadow: 0px -9.5px var(--color-Dark-yellow) inset;
}
.rosado{
    background-color:var(--color-Pink-Light);
    box-shadow: 0px -9.5px var(--color-Dark-Pink) inset;
}
.morado{
    background-color: var(--color-blue-purple);
    box-shadow: 0px -9.5px var(--color-Dark-blue-purple) inset;
}
.color.resaltado {
    opacity: 0.3;
    /* box-shadow: none; */
    animation: undir  0.5s forwards;
    align-self: flex-end;
}

.mensajeTurno {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    border-radius: 5px;
    font-size: 20px;
    opacity: 0;
    animation: fadeInAndGrow 0.5s forwards, fadeOut 0.5s forwards 1s;
}

@keyframes fadeInAndGrow {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.popover >div{
    z-index: 23 !important;
}

.nivel{
    color: white;
    text-transform: uppercase;
    font-size: 2.8vh;
    margin: 1vh 0 0 0;
    display: grid;
    grid-template-columns: repeat(2,2fr);
    padding-left: 4%;
    padding-right: 4%;
    gap: 5%;
}
.nivel span{
    border-radius: 15px;
}
.nivel span:first-child{
    background-color: var(--color-Light-Grey);
}
.nivel span:last-child{
    background-color: var(--color-Pink-Light);
    
}

.pantallaSuperior {
    background: transparent;
    position: absolute;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 10;
    
}
.pantallaSuperior ~ * {
    opacity: 0.4;
}