.resultado_pagina {
    flex-direction: column;
    align-content: center;
    padding-right: 2rem;
    margin-top: 0;
    padding: 2rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
}

.textoTuvisteJuego{
    color: var(--color-Dark-Grey);
    text-align: center;
    font-family: "Montserrat";
    font-size: 3svh ;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 167.5% */
    text-transform: uppercase;
}

.textoTuviste, .resultado{
    color: #512362;
    text-align: center; 
    font-family: "Montserrat";
    font-size: 2.5svh;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 167.5% */
    text-transform: uppercase;
}

.resultado{
    color: var(--color-Pink-Light);
    font-size: 5svh;
    margin-bottom: 2svh;
}

.subtitleRespuestas{
    color: var(--color-purple-dark);
    font-family: 'Montserrat';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.5625rem; /* 125% */
    text-transform: capitalize;
    margin: 0;
}

.resultado_mesa, cedula{
    border: 1px solid white;
}

.calculator {
    padding-bottom: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.sin_input {
    visibility : hidden;
}

.sin_input {
    opacity: 0;
}

.correcta {
    color: white;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-weight: 800;
    text-transform: capitalize;
    background: var(--color-green);
    width: 100%;
    height: fit-content;
    border-radius: 15px;
    align-self: center;
}
.correctaImagen{
    border-radius: 15px;
    border: 2px green solid;
}
.incorrecta {
    color: white;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-weight: 800;
    text-transform: capitalize;
    width: 100%;
    background: var(--color-red);
    border-radius: 15px;
    height: fit-content;
    align-self: center;
}

.incorrectaImagen{
    border-radius: 15px;
    border: 2px red solid;
}

.conclusion_correcta {
    color: green;
}

.conclusion_incorrect {
    color: red;
}
.conclusion_correcta, 
.conclusion_incorrect {
    font-size: 3em;
    padding: 1em;
}

table, th, td {
  border: 1px solid;
  margin: 0  auto;
  padding: 1em;
}

.icon {
    font-size: 50px;
}

.playIcon {
    font-size: 196px;
}

.btnPlay {
    height: 100%;
}

.sumContainer {
    padding-right: 2rem;
    padding: 2rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
}

.sumContainer h1 {
    font-size: 10rem;
    text-align: center;
}

.contenido {
    text-align: center;
    width: 100%;
    height: fit-content;
}

.palabra {
    margin-top: 3.9svh;
    font-size: 6vw;
    font-weight: 600;
    height: fit-content;
    text-align: center;
    color: var(--color-Dark-Grey);
}
@media only screen and (min-aspect-ratio: 7 / 8){
    .palabra{
        font-size: calc(70svh * 0.06);
    }
}
.nombre {
    font-size: 2.5em;
    height: fit-content;
}
.numero {
    font-size: 5em;
    border: 1px solid white;
}

.toolbar {
    /* correct */
    display: flex;
    justify-content: space-between;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 2vh;
    margin-bottom: 1vh;

    color: var(--dark-grey, #404040);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120% ; /* 125% */
    text-transform: uppercase;
    width: 100%;
}

.toolbar p{
    /* correct */
    font-family: inherit;
    font-weight: inherit;
}

.toolbar_centrado {
    /* correct */
    justify-content: center;
}

.item_number {
    text-align: left;
}

.resultado_lista {
    font-size:4em;
}

.home_button {
    height:2em;
    width:5em;
    font-size:3em;

}

.linea{
    /* correct */
    background-color: #512362;
    position: relative;
    right: 0%;
    height: 0.25vh;
    width: 100%;
}

.col {
    display: flex;
    justify-content:center;
    align-items: center;
    color: black;
    /* font-size:'1.5rem'; */

    text-transform: capitalize;
    /* padding-left: 7%; */
  }
  
.grid_resultados{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2%;
    row-gap: 5%;
    font-family: 'Montserrat';
}

.gridResultadosNumPal{
    grid-template-columns: 1fr 1fr !important;
}

.gridResultados2{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2%;
    row-gap: 5%;
    padding: 12px;
}

.gridResultados2NumPal{
    grid-template-columns: 1fr 1fr !important;
    margin: 0 3vh;
    justify-items: center;
}

.grid_resultados_div{
    width: 100%;
    max-height: 10vh;
    overflow: hidden;
    border: 1svh solid white;
}

.grid_resultados_div p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 10svh;
}

.contenedor_miniImagen{
    height: 10vh;
    width: 10vh;
    border-radius: 15px;
    overflow: hidden;
}

.contenedor_resultados{
    height: fit-content;
    max-height: 24.7svh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: var(--color-Pink-Light) var(--color-Light-Grey);
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 28px;
    column-gap: 18px;
    margin: 17px 0;
}
 
.contenedor_resultados::-webkit-scrollbar-thumb {
    background-color: var(--color-Pink-Light);
}
.contenedor_resultados::-webkit-scrollbar {
    width: 5px;
    background-color: #D9D9D9;
}

.resultadosEntrenamiento{
    font-size: 6.5rem;
    margin: 5% 0%;
    font-family: Source Sans Pro;
    font-weight: 700;
}

.sibtitleEntrenamiento{
    font-size: 3rem;
    margin: 5% 0%;
    font-family: Source Sans Pro;
    font-weight: 800;
    text-transform: uppercase;
}

.div_nv_button{
    display: flex;
    justify-content: center;
    transform: translateX(calc(-4.18svh + 7.72px));
    align-items: center;
    margin: 3.9svh 0;
    font-size: 20px;
    font-weight: 600;
    gap: 5px;
}

.nv_button{
    background: var(--color-Pink-Light);
    border: none;
    border-radius: 15px;
    height: 7.59svh;
    aspect-ratio: 1 / 1;
    color: white;
}

.nv_button_small{
    height: 4.18svh;
    justify-content: center;
    margin-right: 2.75px;
}

.nv_button_desactive{
    background-color: var(--color-Light-Grey);
}

.containTableResultados{
    overflow-y: auto;
    scrollbar-color: var(--color-Pink-Light) var(--color-Light-Grey);
    scrollbar-width: thin;
}

.containTableResultados ::-webkit-scrollbar-button {
    background-color: red transparent transparent transparent;
}

.tableResultados{
    display: grid;
    padding: 0;
    border: none;
    row-gap:10px
}

.tableResultados p{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tableItem_img{
    max-height: 10svh;
}

.tableItem_img img{
    height: 100%;
}

.tableItem_borderBottom{
    color: var(--color-purple-dark);
    font-weight: 700;
    font-size: 2svh;
    border-bottom: 2px solid var(--color-purple-dark);
}