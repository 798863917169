body {
  font-family: sans-serif;
  display: grid;
  height: 100vh;
  place-items: center;
}

.stopwatch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 20px;
  padding-top: 30px;
  /* width: 300px;
  height: 300px; */
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.stopwatch__display {
  font-size: 48px;
  margin-bottom: 20px;
}

.stopwatch__controls button {
  font-size: 16px;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.stopwatch__controls button:hover {
  background-color: #ddd;
}