.marginHeaderTest
{
  margin-bottom: -20vh;
}
.testToolbar
{
  --min-height:32vh;
}
.titleTest
{
  margin-bottom: 17.5vh;
  margin-top: 0;
  font-size: 3.79vh;
}
.back{
    margin-bottom: 17.5vh;
    --color: white;
    width: fit-content;
}
.back::part(native){
    padding: 0;
}
.paginaInicial, .paginaTests, .paginaTestTerminados{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-style: normal;

}
.paginaInicial{
    font-family: 'Montserrat';
    font-size: 2.4vh;
    padding: 5.45vh 9% 0 9% ;
    font-weight: 400;
    line-height: 23px;
}
.parrafoUno{
    margin: 0;
    line-height: 1.2;
}
.titulo{
    color: #512362;
    font-weight: 700;
    line-height: 40px;
    font-size: 5.68vh;
    margin-top: 2.37vh;
    margin-bottom: 3.43vh;
}
.buttonPlay{
    height: 22.63vh;
    width: 22.63vh;
    margin-bottom: 4vh;
    background: transparent;
}
.buttonPlay::part(native){
    padding: 0;
}
.icon{
    width: 100%;
    height: 100%;
}
.parrafoDos, .parrafoTres{
    margin-top: 0;
}
.parrafoDos{
    margin-bottom: 3.55vh;
}
.parrafoTres{
    margin-bottom: 0;
}

/* estilos para cuando se muestan los test faltantes y realizados */
.paginaTests, .paginaTestTerminados{
    width: 100%;
    padding: 9.48%;
}
.labelTest{
    color:  var(--color-purple-dark);
    font-family: "Source Sans Pro";
    font-size: 2.84vh;
    font-weight: 700;
    line-height: 40px;
}
.finalizados, .faltantes{
    width: 100%;
    height: 10.78vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding-left: 2.53%;
    padding-right: 2.53%;
    margin-bottom: 1.54vh;
}
.finalizados{
    
    background:  #512362;

}
.faltantes{
    background:  linear-gradient(180deg, var(--color-purple-dark) 0%, var(--color-Pink-Light) 100%);

}
.lineaTest{
    margin-top: 2.48vh;
    margin-bottom: 2.96vh;
    background-color: black;
    width: 100%;
    height: 1px;
}
.imagenTest{
    border-radius: 15px;
    height: 8.4vh;
    width:  8.4vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nombreTest{
    color: white;
    font-family: "Source Sans Pro";
    font-size: 2.37vh;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    text-transform: uppercase;
    text-align: end;
    flex: 1; 
    padding-right: 4.11%;
}
.icon{
    width: 85%;
    height: 85%;

}
/* estilos para cuando se termino todos los test*/

.felicidades{
    color:  var(--color-purple-dark);
    font-family: "Source Sans Pro";
    font-size: 4.26vh;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; 
    margin-bottom: 0;
    text-align: center;
}
.parrafoResultados{
    color: #404040;
    margin-top: 0;
    margin-bottom: 2.5vh;
    font-family: "Montserrat";
    font-size: 1.9;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; 
    text-align: center;
}

/* estilos para show result de test */

.showResult{
    padding-left: 8.97%;
    padding-right: 8.97%;
    padding-top: 4.74vh;
    text-align: center;
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tituloResultado{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-Pink-Light);
    font-family: "Source Sans Pro";
    font-size: 4.74vh;
    font-weight: 700;
    line-height: 21.5px;
    margin: 0;
    height: 5vh;
}
.fecha{
    color: #868189;
    font-family: "Source Sans Pro";
    font-size: 2.37vh;
    font-weight: 700;
    line-height: 21.5px;
    text-transform: uppercase;
    margin: 1.18vh 0 0 0;
}

.circular{
    height: 36vh;
    width: 36vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4.7vh;
}
.bordes{
    position: absolute;
    transform-origin: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #868189;
    box-sizing: border-box;

}
.bordesMorados{
    border: 3px solid #512362 !important;
}
.bordesRosados{
    border: 3px solid var(--color-Pink-Light) !important;
}
.circular .interno, .circular .outer, .circular .circulo{
    position: absolute;
    
    height: 100%;
    width: 100%;
    border-radius: 100%;
    box-shadow: inset 0 1px 0 rgba(0,0,0,0.2);
}
.circular .interno{
    z-index: 8;
    top: 50%;
    left: 50%;
    height: 20.2vh;
    width: 20.2vh;
    transform: translate(-50%,-50%);
    background-color: white;
    border-radius: 100%;
    border: 3px solid #868189;
}
.internoRosado{
    border: 3px solid var(--color-Pink-Light) !important;
}
.internoMorado{
    border: 3px solid #512362 !important;
}
.circular .circulo{
    z-index: 1;
    
}
.circular .numero {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    color: #868189;
    font-family: "Source Sans Pro";
    font-size: 9.7vh;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 1.44px;
    text-transform: uppercase;
}
.numeroRosado{
    color: var(--color-Pink-Light) !important;
}
.numeroMorado{
    color: #512362 !important;
}
.porcentaje{
    font-size: 4.26vh;
}
.circular .bar{
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-border-radius: 100%;
    
    clip: rect( 0vh, 36vh,36vh, 18vh);   
}
.circulo .bar .progress{
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-border-radius: 100%;
    clip: rect(0px,  18vh, 36vh, 0vh); 
}
.circulo .bar .progress, .dot span{
    background:  #868189;
}

.progresoMorado{
    background:  #512362 !important;
}
.progresoRosado{
    background: var(--color-Pink-Light) !important;
}
.circulo .left .progress{
    z-index: 1;
}
@keyframes left {
    100%{
        transform: rotate(180deg);
    }
}
.circulo .right{
    z-index: 3;
    transform: rotate(180deg);
}
.circulo .right .progress{
    animation-delay: 1s;
}
.progress2{
    
}
@keyframes right {
    100%{
        transform: rotate(180deg);
    }
}
.circulo .dot{
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50%;
    height: 10px;
    margin-top: -5px;
    animation: dot 2s linear both;
    transform-origin: 0% 50%;
}
.circulo .dot span {
    position: absolute;
    right: 0;
    width: 15.8vh;
    height: 10px;
}
@keyframes dot{
    0% {
        transform: rotate(-90deg);
    }
    50% {
        transform: rotate(90deg);
        z-index: 4;
    }
    100% {
        transform: rotate(270deg);
        z-index: 4;
    }
}
.lineaCirculo {
    position: absolute;
    width: 3px;
    height: 50%;
    background:  #868189;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0) translate(0, -50%);
    z-index: 5;
    
}
.lineaRosada{
    background: var(--color-Pink-Light) ;
}
.lineaMorada{
    background: var(--color-purple-dark) ;
}
.lineaBlanca{
    background: white !important;
}
.l10 {
    transform: translate(-50%, -50%) rotate(36deg) translate(0, -50%);
}
.l10Blanca {
    transform: translate(-50%, -50%) rotate(38deg) translate(0, -50%);
    background-color: white;
    z-index: 7;
}
.l20 {
    transform: translate(-50%, -50%) rotate(72deg) translate(0, -50%);  
}
.l20Blanca {
    transform: translate(-50%, -50%) rotate(74deg) translate(0, -50%);
    background-color: white;
    z-index: 7;
}
  
.l30 {
    transform: translate(-50%, -50%) rotate(108deg) translate(0, -50%);
}
.l30Blanca {
    transform: translate(-50%, -50%) rotate(110deg) translate(0, -50%);
    background-color: white;
    z-index: 7;
}
  
.l40 {
    transform: translate(-50%, -50%) rotate(144deg) translate(0, -50%);
    
}
.l40Blanca {
    transform: translate(-50%, -50%) rotate(146deg) translate(0, -50%);
    background-color: white;
    z-index: 7;
}
  
.l50 {
    transform: translate(-50%, -50%) rotate(180deg) translate(0, -50%);
}

.l50Blanca {
    transform: translate(-50%, -50%) rotate(182deg) translate(0, -50%);
    background-color: white;
    z-index: 7;
}
  
.l60 {
    transform: translate(-50%, -50%) rotate(216deg) translate(0, -50%);
}

.l60Blanca {
    transform: translate(-50%, -50%) rotate(218deg) translate(0, -50%);
    background-color: white;
    z-index: 7;
}
  
.l70 {
    transform: translate(-50%, -50%) rotate(252deg) translate(0, -50%);
}

.l70Blanca {
    transform: translate(-50%, -50%) rotate(254deg) translate(0, -50%);
    background-color: white;
    z-index: 7;
}
  
.l80 {
    transform: translate(-50%, -50%) rotate(288deg) translate(0, -50%);
}

.l80Blanca {
    transform: translate(-50%, -50%) rotate(290deg) translate(0, -50%);
    background-color: white;
    z-index: 7;
}
  
.l90 {
    transform: translate(-50%, -50%) rotate(324deg) translate(0, -50%);
}
.l90Blanca {
    transform: translate(-50%, -50%) rotate(326deg) translate(0, -50%);
    background-color: white;
    z-index: 7;
}
.l100Blanca {
    transform: translate(-50%, -50%) rotate(358deg) translate(0, -50%);
    background-color: white;
    z-index: 7;
}
.mensaje, .resultado{
    font-family: "Source Sans Pro";
    color: #868189;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}
.mensaje{
    font-size: 4.26vh;
    line-height: 70%; 
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.resultado{
    font-size: 2.36vh;
    line-height: 21.5px;
}

.bordesButton{
    width: 43%;
    height: 5.9vh;
    flex-shrink: 0;
    border-radius: 15px;
    background: var( --color-degradado-vertical);
    padding: 2px;
    margin-top: 2vh;
}
.bordesButton button{
    background: white;
    border-radius: 15px;
    width: 100%;
    height: 100%;
}

.bordesButton button span{
    background:  linear-gradient(180deg, var(--color-purple-dark) 0%, var(--color-Pink-Light) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Montserrat";
    font-size: 1.9vh;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; 
    text-transform: uppercase;
}

.card_memoria_nombres, .card_memoria_palabra{
    border-radius: 20px;
    margin-bottom: 1.54svh;
    padding: 2.96svh 11.66% 0 7.9%;
    color: white;
    text-decoration: none;

    /*altura con respecto a la del padre sin contar el padding */
    height: 28.78%;
    width: auto;

    /*para que mantenga una dimension de width/ height = 1.26*/
    aspect-ratio: 2.26;
}

.card_memoria_palabra{
    /*la imagen de background ya viene recortada entonces solo es ubicarla abajo y a la derecha*/
    background: url(/public/assets/componentes/Juegos/Abc_recortado.svg),
    linear-gradient(148deg, #4E84DC 40.57%, #77B15C 129.02%);
    background-repeat: no-repeat;
    background-position: bottom 0 right 0,0 0;

    /*para que tome la imagen tome de ancho:auto y de alto 70% del alto, y para el linear-gradiente: contain*/
    background-size: auto 70%, contain;
}

.card_memoria_nombres{
    /*la imagen de background ya viene recortada entonces solo es ubicarla abajo y a la derecha*/
    background: url(/public/assets/componentes/Juegos/rostro_recortado.svg),
    linear-gradient(317deg, #4A86DF -10.06%, #74AE64 85.61%);
    background-repeat: no-repeat;
    background-position: bottom 0 right 0,0 0;

    /*para que tome la imagen tome de ancho:auto y de alto 70% del alto, y para el linear-gradiente: contain*/
    background-size: auto 70%, contain;    
}

.card_calculo_fecha h3, .card_calculo_matematico h3, .card_neural_trainer h3,
.card_sc_dice h3, .card_parejas h3, .card_memoria_nombres h3, .card_memoria_palabra h3{
    margin: 0;
    color: #FFF;
    font-size: 1.9svh;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 134.375%;
}

.card_block{
    filter: grayscale(100%)
}