.centrar{
  /* correct */
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

.element_block{
  filter: grayscale(1);
}

.loading{
  /* correct */
  position: absolute;
  left: 0%;
  top: 0%;
  background: radial-gradient(675.17% 143.84% at 100% 143.84%, var(--color-Pink-Light) 0%, var(--color-purple-dark) 100%);
  width: 100%;
  height: 100%;  
}

.texto{
  text-align: justify;
  text-overflow: visible;
  word-wrap: break-word;
  white-space: pre-line;
}

.content-wrapper{
  /* correct */
  height: calc(100%); /* Ajusta la altura según tus necesidades */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; /* Ajusta el valor según la altura de la barra de pestañas */
  z-index: 1;
}

.table-col {
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  padding:0 0 0 5px !important;
}

.column-rank{
  width: 10%;
}
.column-name{
  width: 1px;
}
.column-nivel{
  width: 1px;
}
.column-tiempo{
  width: 1px;
}


.custom-modal{
  --width: 80%; 
  --height: 30%; 
}

.divAllView{
  height: 99.16svh;
  width: 100%;
  overflow: hidden;
  background-color: white;
  top: 0;
  position: absolute;
  z-index: 1;
}

@media (min-aspect-ratio: 7/8) {
  .divAllView{
    max-width: 70vh;
  }
}

bg{
    background-color: aqua;
}
  b {
    font-weight: 500;
  }


  /* p {
    padding: 0 40px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--ion-color-step-600, #60646b);
  } */


  p b {
    color: var(--ion-text-color, #000000);
  }
  