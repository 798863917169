@keyframes montar {
    100% {
      transform: translateY(0);
    }
    0% {
      transform: translateY(100%);
    }
  }
  @keyframes desmontar {
    100% {
      transform: translateY(100%);
    }
    0% {
      transform: translateY(0);
    }
  }
.desmontar {
    animation: desmontar 1s ease-in-out forwards;
}
.montar {
    animation: montar 1s ease-out;
}
.contenedor {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2000;
    background: transparent;
} 
.contenedor ~ *{
    opacity: 0.4;
}

.contenido{
    flex-direction: column;
    width: 100%; 
    height: 100%;
    justify-content: end !important;
    animation: montar 1s ease-out;
}
.contenido,  .bordesNeuronas, .circulo, .logoNeuronas, .svgNeurona{
    display: flex;
    align-items: center;
    justify-content: center;
}
.circulo{
    background: #FFF;
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 8px 4px 0px rgba(0, 0, 0, 0.25),-4px 0px 4px 0px rgba(0, 0, 0, 0.25);
    width: 22.79vh;
    height: 22.79vh;
    
}
.circulo, .bordesNeuronas, .logoNeuronas{
    border-radius: 50%;
}
.bordesNeuronas{
    background: white;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1.5vh;
    z-index: 1004;
}

.logoNeuronas{
    z-index: 1004;
    width: 16.3svh;
    height: 16.3svh;
}
.svgNeurona{
    position:absolute;
    z-index: 1004;
    width: 13.7svh;
    height: 16.5svh;
}

.rectangulo {
    border-radius: 15px 15px 0 0;
    background: #FFF;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: -9.7vh;
    width: 100%;
    flex-direction: column;
    padding-top: 9.71vh;
    padding-bottom: calc(7.99vh + 50px);
    display: flex;
    align-items: center;
    height: fit-content;
    text-align: center;
}
.marginMensaje{
    margin-bottom: 2.48vh !important;
}
.mensajePersonalizado, .neuronas, .totalNeuronas, .parrafoRespuestas{
    font-family: 'Montserrat';
    font-style: normal;
    padding-left: 5%;
    padding-right: 5%;
}
.mensajePersonalizado{
    color: var(--color-purple-dark);

    font-style: normal;
    font-family: "Montserrat";
    line-height: 105%;
    font-weight: 600;
    align-items: center;
    display: flex;
    z-index: 1005;

    font-size: 3.65svh;
    height: auto;
    margin: 0px;
   
}

.neuronas{
    font-size: 2.15svh;
    margin: 3.5vh 0 0 0;
}

.totalNeuronas{
    height: 2.5vh;
    font-size: 1.66vh;
    margin: 0 0 2.5svh 0;
    font-style: normal;
    line-height: 40px;
    color:#404040;
    align-items: center;
    display: flex;
}

.parrafoRespuestas{
    text-align: center;
    color:  #868189;
    font-size: 2.2vh;
    margin: 0;
    line-height:  110.5% ;
}

.respuestasCorrectas{
    color: var(--color-Pink-Light) !important;
}

.respuestaCorrectaSumas{
    margin:1vh 0 3.55vh 0 !important;
    font-size: 1.8vh !important;
}

.mensajes{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
}