
.card_test{
    width: 80%;
    height: 10%;
    color: black;
    background-color:rgba(134, 129, 137, 0.5) ;
    border-radius: 15px;
    text-align: center;
    margin-top: 10px;
}

.no_hay_test{
    color: black;
}
.button_crear_test{
    height: 7%;
    width: 60%;
    background-color: #D74286;
    border-radius: 15px;
    color: white;
    font-size: 2.37svh;
    text-transform: uppercase;
}
.modal_overlay{
    position: fixed;
    top: 5%;
    left: 0;
    width: 100%;
    height: 95%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.content_modal{
    padding: 5%;
    color: #868189;
}