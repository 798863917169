.fondo{
    background-color: #FFF;
    width: 100%;
    height: 100%;
    color: black;
    text-align: center;
}

.content_background{
    background: url(../../../../public/assets/images/fondoRuta.png) top left / cover , white !important;
}

.begin{
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    transition: height 0.5s ease-in, padding 0.5s ease-in;
    color: white;
    padding: 6.977svh 1.62svh;
}

.begin h1{
    font-size: 2.576svh;
    text-transform: uppercase;
    color: var(--color-purple-dark);
    font-weight: 700;
    padding: 0 6.977svh;
    text-align: center;
}

.begin button:hover{
    box-shadow: none;
    border: 2px solid black;
}

.begin p{
    font-weight: 500;
    color: var(--color-Medium-Grey);
    margin: 2.685svh 0;
    padding: 0 60px;
    text-align: center;
}

.begin div{
    justify-content: center;
    display: flex;
    position: relative;
    margin: 0 10px;
}
.begin div p{
    color: var(--color-Dark-Grey);
    padding: 5px;
    width: fit-content;
    background: white;
    z-index: 2;
    font-size: 24px;
    font-weight: 700;
}

.begin div::before{
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    background: var(--color-Medium-Grey);
    top: 50%;
    z-index: 1;
}

.begin button{
    height: fit-content;
    padding: 2.476svh 85px;
    width: 100%;
    border-radius: 25px;
    box-shadow: none;
    font-size: 2.576svh;
    line-height: 200%;
    font-weight: 700;
}

.begin_button_host{
    background: var(--color-Pink-Light);
    color: inherit;

}

.begin_button_player{
    color: var(--color-Pink-Light);
    background-color: transparent;
    border: 2px solid var(--color-Pink-Light);
}

.juegoGrid{
    width: 100%;
    height: fit-content;
    gap: 15.16px;
    display: grid;
    padding: 6.4svh 1.62svh 2svh;
}

.juegoGrid button:nth-child(n) .juegoGrid_circle{
    height: 2.5svh;
    aspect-ratio: 1 / 1;
    position: absolute;
    top: calc(50% - 1.25svh);
    left: calc(5% - 1.25svh);
    border-radius: 100%;
    z-index: 1;
}

.juegoGrid button:nth-child(n){
    height: 100px;
    width: 100%;
    border-radius: 25px;
    padding: 25px;
    padding-left: calc(25px + 10%);
    position: relative;
    border: none;
    text-align: start;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    align-items: start;
    display: flex;
}

.juegoGrid button:nth-child(n)::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 25px;
    width: 10%;
    height: 100%;
    background-color: var(--color-Light-Grey);
}

.cardSumas{
    background: linear-gradient(147deg, #FA887B 3.24%, var(--color-Pink-Light) 108.15%);
}

.cardSumas::before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(../../../../public/assets/componentes/Juegos/calculo_matematico_recortado.svg) bottom right / auto 80% no-repeat;
}

.cardSimon{
    background: linear-gradient(130deg, #5755CA 8.51%, var(--color-Pink-Light) 125.89%);
}

.cardSimon::before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(../../../../public/assets/componentes/Juegos/sc_recortado.svg) bottom right / auto 80% no-repeat;
}

.cardNombres{
    background: linear-gradient(317deg, #4A86DF -10.06%, #74AE64 85.61%);
}

.cardNombres::before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(../../../../public/assets/componentes/Juegos/rostro_recortado.svg) bottom right / auto 80% no-repeat;
}

.cardPalabras{
    background: linear-gradient(148deg, #4E84DC 40.57%, #77B15C 129.02%);
}

.cardPalabras::before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(../../../../public/assets/componentes/Juegos/parejas_recortado.svg) bottom right / auto 80% no-repeat;

}

.cardParejas{
    background: linear-gradient(321deg, #5755CA -1.25%, #4E84DC 97.64%);
}

.cardParejas::before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(../../../../public/assets/componentes/Juegos/parejas_recortado.svg) bottom right / auto 80% no-repeat;

}

.desactive{
    background: var(--color-Light-Grey);
}

.qrBox{
    width: 80%;
    aspect-ratio: 1 / 1;
    padding: 10px;
    border: 2px solid var(--color-purple-dark);
    border-radius: 20px;
    margin-top: 6svh;
    margin-bottom: 2svh;
}

.sala{
    color: var(--color-purple-dark);
    font-size: 24px;
    font-weight: 700;
}

.sala a{
    text-decoration: none;
    color: var(--color-Medium-Grey);
    font-size: inherit;
}

.jugadores{
    width: 39%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2svh 0 5svh;
}

.jugadores h1{
    background: var(--color-Pink-Light);
    border-radius: 15px;
    font-size: 11.2px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    width: -moz-fit-content;
    width: 100%;
    padding-top: 5%;
    height: 100%;
    text-align: center;
    line-height: 200%;
}

.jugadores p{
    width: 100%;
    text-align: center;
    border-radius: 15px;
    font-size: 44.8px;
    font-weight: 700;
    line-height: 184.5%;
    color: var(--color-Medium-Grey);
    background: var(--color-White-Grey);
    border: 1px solid var(--color-Medium-Grey);
}

.button_disable{
    background: var(--color-Medium-Grey) !important;
}
.button_able{
    background: var(--color-purple-dark) !important;
}

.containerRank{
    display: grid;
    width: 100%;
    padding: 44px 17px;
    gap: 10px;
}

.containerRank div:nth-child(n) :nth-child(2){
    justify-self: flex-start !important;
}

.containerRank div:nth-child(n){
    display: grid;
    grid-template-columns: 15% 1fr 1fr;
    justify-items: center;
    align-items: center;
    box-shadow: 0px 3px 5px 1px var(--color-Medium-Grey);
    background: white;
    padding: 20px;
    border-radius: 10px;
    color: var(--color-Medium-Grey);
    font-weight: 600;
    height: fit-content;
    line-height: 100%;    
}

.containerRank div:nth-child(n) :nth-child(n){
    color: inherit;
    line-height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.containerRank div:nth-child(n) :first-child{
    font-size: 24px;
}
.containerRank div:nth-child(n) :first-child::before{
    content: "#";
}

.containerRank div:nth-child(1){
    position: relative;
    color: white;
    background: var(--color-yellow);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.containerRank div:nth-child(1) :first-child::before{
    content: "#";
}

.containerRank div:nth-child(1) :first-child{
    position: absolute;
    right: 20px;
    font-size: 36px;
    font-weight: 800;
    top: calc(50% - 18px);
    line-height: 100%;
}

.containerRank div:nth-child(1) :nth-child(2){
    font-size: 24px;
}

.containerRank div:nth-child(1) :nth-child(3){
    font-size: 14px;
}

.containerRank div:nth-child(2){
    position: relative;
    color: white;
    background: var(--color-purple-dark);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 7px;
}

.containerRank div:nth-child(2) :first-child::before{
    content: "#";
}

.containerRank div:nth-child(2) :first-child{
    position: absolute;
    right: 20px;
    font-size: 36px;
    font-weight: 800;
    top: calc(50% - 18px);
    line-height: 100%;
}

.containerRank div:nth-child(2) :nth-child(2){
    font-size: 24px;
}

.containerRank div:nth-child(2) :nth-child(3){
    font-size: 14px;
}

.containerRank div:nth-child(3){
    position: relative;
    color: white;
    background: var(--color-Pink-Light);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 7px;
}

.containerRank div:nth-child(3) :first-child::before{
    content: "#";
}

.containerRank div:nth-child(3) :first-child{
    position: absolute;
    right: 20px;
    font-size: 36px;
    font-weight: 800;
    top: calc(50% - 18px);
    line-height: 100%;
}

.containerRank div:nth-child(3) :nth-child(2){
    font-size: 24px;
}

.containerRank div:nth-child(3) :nth-child(3){
    font-size: 14px;
}

.containerRank.final div:nth-child(1){
    display: none;
}
.containerRank.final div:nth-child(2){
    display: none;
}
.containerRank.final div:nth-child(3){
    display: none;
}

.lastRank{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 300px;
    padding: 10px;
}

.lastRank >div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    font-weight: 700;
}

.lastRank .first div{
    font-size: 24px;
    height: 70%;
    background: var(--color-yellow);
    width: 100%;
    position: relative;
    padding-top: 20px;
    overflow: hidden;
}
.lastRank .first div::before{
    content: "";
    top: -40px;
    width: 100%;
    border-right: 60px solid transparent;
    border-left: 60px solid transparent;
    border-bottom: 50px solid #EA801E;
    position: absolute;
    background: white;
}
.lastRank .first :first-child{
    color: var(--color-purple-dark);
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    margin: 5%;
}
.lastRank .first :nth-child(2){
    background: var(--color-yellow);
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: white;
    width: 60%;
    border-radius: 20px;
    margin: 15px 0;
}


.lastRank .second div{
    font-size: 15px;
    background: var(--color-purple-dark);
    height: 45%;
    width: 100%;
    position: relative;
    padding-top: 20px;
    overflow: hidden;
}
.lastRank .second :first-child{
    color: var(--color-purple-dark);
    font-weight: 700;
    font-size: 15px;
    line-height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    margin: 5%;
}
.lastRank .second div::before{
    content: "";
    top: -40px;
    width: 100%;
    border-right: 60px solid transparent;
    border-left: 60px solid transparent;
    border-bottom: 50px solid #301856;
    position: absolute;
    background: white;
}
.lastRank .second :nth-child(2){
    background: var(--color-purple-dark);
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: white;
    width: 60%;
    border-radius: 20px;
    margin: 15px 0;
}

.lastRank .third div{
    font-size: 15px;
    height: 35%;
    background: var(--color-Pink-Light);
    width: 100%;
    position: relative;
    padding-top: 20px;
    overflow: hidden;
}
.lastRank .third :first-child{
    color: var(--color-purple-dark);
    font-weight: 700;
    font-size: 15px;
    line-height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    margin: 5%;
}
.lastRank .third div::before{
    content: "";
    top: -40px;
    width: 100%;
    border-right: 60px solid transparent;
    border-left: 60px solid transparent;
    border-bottom: 50px solid #C93651;
    position: absolute;
    background: white;
}
.lastRank .third :nth-child(2){
    background: var(--color-Pink-Light);
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: white;
    width: 60%;
    border-radius: 20px;
    margin: 15px 0;
}

.titleRanking{
    margin: 20px;
    font-size: 24px;
    color: var(--color-Light-Grey);

}

.divConfirmation{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    text-align: center;
}

div.nombrar{
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    padding: 5%;
    width: 100%;
}

div.nombrar label{
    text-align: center;
    color: var(--color-Medium-Grey);
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
}

div.nombrar input{
    border: 2px solid var(--color-Medium-Grey);
    font-size: 20px;
    line-height: 200%;
    background: var(--color-White-Grey);
}

div.nombrar button.nombrar{
    margin: 0;
    line-height: 200% !important;
    padding: 3% !important;
    font-size: 20px !important;
}

div.nombrar div{
    font-size: 20px;
    font-weight: 600;
    color: var(--color-Medium-Grey);
    width: 100%;
    position: relative;
    text-align: center;
    z-index: 2;
}

div.nombrar div::before{
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    background: var(--color-Medium-Grey);
    top: 50%;
    z-index: -1;
}

div.nombrar button.blanco{
    border: 2px solid var(--color-Pink-Light);
    border-radius: 15px;
    width: 100%;
    color: var(--color-Pink-Light);
    font-weight: 700;
    line-height: 200%;
    height: fit-content;
    padding: 3%;
    background: white;
    font-size: 20px;
}

.buttonSync{
    position: absolute;
    right: 2em;
    top: 0.5em;
    transform: rotate(12deg);
}

.buttonSync:hover{
    transition: all 1s ease-in-out;
    transform: rotate(90deg);
}