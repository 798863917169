

.fondo{
    width: 100%;
    height: 92svh;
    background: #FFF;
    font-family: 'Montserrat';
    font-style: normal;
    overflow-y: scroll;
}

#page {
    max-height: 100vh; /* Limita la altura máxima de la página al tamaño de la pantalla */
    overflow-y: auto; /* Habilita el desplazamiento vertical */
    background: white; /* Asegura el fondo blanco de la página */
  }  

.backButtonEntrenamiento{
    border: none;
    position: absolute;
    background: url(../../../public/assets/icon/flechaBlancaMorado.svg) center left/ contain;
    background-repeat: no-repeat;
    height: 2.84svh;
    width: 20%;
    aspect-ratio: 0.588;
    left: 5.12%;
    justify-self: center;
}

.backButtonEntrenamiento::after{
    content: "VOLVER";
    font-weight: 700;
    font-size: 1em;
    color: var(--color-purple-dark);
    position: relative;
    left: 10%;
}

.pageRealizarActividad{
    height: 93.62% !important;
  
  }
/*
 *  contenedor donde se van a mapear las actividades
 */
.contenedor_actividades{
    display: flex;
    font-family: 'Montserrat';
    flex-direction: column;
    background: url(/public/assets/componentes/Actividades/NEURONAS.svg), #FFF;
    background-repeat: repeat;
}

/**
 * contenedor para el titulo de la interfaz de actividades para una secion
 */
.contenedor_titulo_actividad{
    color: var(--color-purple-dark);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 13.54%;
    width: 100%;
    padding-top: 6.516svh;
    position: relative;
}
.dia{
    color: var(--color-purple-dark);
    text-align: center;
    font-family: inherit;
    font-size: 4.74svh;
    font-weight: 700;
    line-height: 62.5%;
    text-transform: uppercase;
}
/**
 *  estilos para el titulo
 */
.contenedor_titulo_actividad h1{
    font-size: 2.84svh;
    font-weight: 700;
    line-height: 166.667%;
    text-transform: uppercase;
    margin: 0;
    color: var(--color-purple-dark);
}

.backButton{
    position: absolute;
    left: 5.41%;
    top: 4.74svh;
}
.paragrafo {
    color: var(--white, #FFF);
    text-align: center;
    /* leading-trim: both;
    text-edge: cap; */
    font-family: Source Sans Pro;
    font-size: 3vh;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 104.167% */
    text-transform: uppercase;
    color: white;
    display: flex;
    justify-content: center;
    padding: 0% 10% 0% 10%;
}

.titulo{
    /* position: relative; */
    color: #FFF;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 3.5vh;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 125% */
    margin: 0% 15%;
    margin-top:3.45vh;
    text-transform: uppercase;
}

.lista-sesiones{
    background-color: transparent;
    padding: 10% 15% 10% 15%;
    overflow: visible;
}
.lista-sesiones li{
    margin-bottom: 10px;
}

.circulo{
    z-index: 13;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid white;
    border-radius: 50%;
    width: 8.4vh;
    height: 8.4vh;
    /* background-color: var(--color-purple-dark); */
}

.fondo-blanco{
    color: black;
    background-color: #FFF;
}
.fondo_morado{
    color: white;
    background-color: var(--color-purple-dark);
    box-shadow: 0px 0px 12px 0px rgba(255, 178, 28, 0.50);
}
.fondo_gris{
    border-radius: 15px;
    background: #D9D9D9;
}
.fondo-rosado{
    color: white;
    background-color: #D74286;
}
.fondo_transparente{
    background: transparent;
}

.linea-curva{
    position: absolute;
    background-color: white;
    border-top-right-radius: 100% 100px;
    border-bottom-right-radius: 100% 100px;

    width: 40%;
    height: 30%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.cu1{
    position: relative;
    margin-left: 6vh;
    margin-top:-5vh;
    margin-bottom: -7vh;
    width: 30%;
    height: 25%;
    background: transparent;
    z-index: 10;
}

.cu2{
    position: relative;
    margin-left: 6.2vh;
    margin-top:-6vh;
    margin-bottom: -5vh;
    width: 30%;
    height: 25%;
    background: transparent;
    transform: rotate(-95deg);
    z-index: 10;
}

.cu3{
    position: relative;
    margin-left: 55%;
    left: 0%;
    margin-top:-6vh;
    margin-bottom: -5vh;
    width: 30%;
    height: 25%;
    background: transparent;
    transform: rotate(85deg);
    z-index: 10;
}

.cu0{
    position: relative;
    margin-left: 55%;
    left: 0%;
    margin-top:-6vh;
    margin-bottom: -5vh;
    width: 30%;
    height: 25%;
    background: transparent;
    transform: rotate(-175deg);
    z-index: 10;
}

.mensajeEmergenteSesion{
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color:transparent;
    width: 70%;
    height: 10vh;
    top: 10%;
    left: 30%;
    border-radius: 20px;
    z-index:17;
}

.subtituloMensajeSesion{
    display: flex;
    margin: 2% 5% 2% 5%;
    padding: 0%;
    width: 60%;
    font-size: 2.3vh;
    font-weight: 700;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    color: var(--color-purple-dark);
    overflow: hidden;
    white-space:normal;
    text-overflow: ellipsis;

}

/*
 * hay tres tipos de actividades: realizadas, bloqueadas y la actual
 * atributos que comparten las 3 actividades
 */
.activity_box,.activity_box_complete,.activity_box_blockeado{
    width: 100%;
    height: 12.3svh;
    margin-bottom: 10%;
    border-radius: 1.77em;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 5% 5% 5% 5%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5%;
    position: relative;
    background: white;
}
.activity_box{
    background-color: #FFF;
    box-shadow: 0px 0px 30px 0px rgba(255, 178, 28, 0.50);
}

.activity_box_complete{
    background:url(/public/assets/componentes/Actividades/Subtract.svg);
    background-size:contain;
    background-repeat: no-repeat;
    /* width: auto !important; */
}

/*
 * etiqueta: img
 * imagen: estrellas
 * en la pantalla de actividades, la actividad realizada cambia su icono a estrellas
 */
.imagen_actividad_realizada{

    /* se ubica con respecto al tamaño de la card que contiene la actividad, esto para cada actividad */
    /* class_contenedor: activity_box_complete */

    position: absolute;
    right: -11.4%;
    top: -22%;
    
    /* alto con respecto a el contenedor */
    height: 54.6%;
    width: auto;
}
/*
 * estilos para el contenedor del icono de darle play a la actividad
 * cuando la actividad es realizada el icono cambia de color (morado a amarillo)
 */
.icono_play_actividad{

    /* se ubica con respecto a la card que contiene la actividad, esto para cada actividad */
    /* class_contenedor: activity_box_complete */

    display: flex;
    position: absolute;
    right: 8%;
    bottom: 5%;
    height: 12.3%;
    width: auto;
}
.texto-morado-titulo-actividad{
    color: var(--purple-dark, #512362);
    text-align: center;
    font-family: inherit;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 700;
    line-height: 0.96;
    text-transform: uppercase;
    padding-top: 0%;
    margin-top: 0%;
}

.texto-subtitulo-actividad{
    color: #404040;
    font-family: 'Montserrat';
    font-size: 1.65svh;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; 
}

.bloqueo_actividad{
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 0.9375rem;
    width: 100%;
    height: 100%;
    background-color: #512362;
    opacity: 0.9;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
}

.descripcionEntrenamiento{
    margin-top: 25%;
    margin-bottom: 5%;
    height: fit-content;
    padding: 2% 2% 2% 2%;
}

.contenedorVideoFisrtTime{
  background-color: transparent;
  /* height: fit-content; */
  height: 12.375rem;
  overflow: hidden;
  padding: 0%;
  width: 90%;
  border-radius: 3.8125rem;;
  z-index: 2000;
  position: absolute;
  top: 15%;
  margin: auto;
  left: 50%; 
  transform: translateX(-50%); 

}

.checkmarkPersonalizada{
    /* correct */
    height: 2vh;
    width: 2vh;
    min-width: 2vh;
    border: 1.5px solid #512362;
}

.expand-div{
    border-radius: 20px;
    padding: 2vh;
    border: 2px solid #512362;
    width:100%;
    cursor: pointer;
    transition: height 1s ease-in-out;
    overflow: hidden;
}
/* .expand-div:hover {
    transform: scale(1.2); 
} */

.fondo-blanco{
    background-color: #FFF;
}
.fondo-morado{
    background-color: var(--color-purple-dark);
}
.informacion{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    font-style: normal;
    padding-left: 2%;
    padding-right: 2%;

}
.informacion, .list_informacion{
    padding-left: 3.5%;
    padding-right: 3.5%;
}
.title_informacion{
    color: var(--color-purple-dark);
    text-transform: uppercase;
    font-size: 2.36vh;
    line-height: 125%;
    font-weight: 700;
    
}
.list_informacion div{
    font-family: 'Montserrat';
    color: #404040;
    font-size: 1.8vh;
    font-style: normal;
    font-weight: 400;
    line-height:  166.667%;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.list_informacion{
    width: 100%;
}
.icon_information{
    color: var(--color-purple-dark);
    margin-right: 5%;
    font-size: 2.26vh;
} 
.pagar{
    background:var(--color-purple-dark);
    color:#FFFFFF;
    text-transform: uppercase;
    height: 7.7vh;
    border-radius: 13.5px;
    width: 20vh;
    font-family: 'Montserrat';
    font-size: 1.89vh;
    font-weight: 600;
    box-shadow: 2px 4px 2px -1px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.volver{
    background:white;
    height: 5vh;
    border-radius: 13.5px;
    border-color: #D74286;
    border-style: solid;
    border-width: 1px;
    width: 13.8vh;
    font-family: 'Montserrat';
    font-size: 1.89vh;
    font-weight: 600;
    width: 100%;
}

.contenido_popover {
    border-radius: 15px 15px 0 0;
    background: #FFF;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    z-index: 1001;
    flex-direction: column;
    display:flex; 
    font-style: normal;
    align-items: center;
    font-family:  'Montserrat';
    padding-left: 5%;
    padding-right: 5%;
}
.confirmacion_pedido{
    color: var(--color-purple-dark);
    text-align: center;
    font-size: 1.9vh;
    font-weight: 700;
    line-height:  250%;
    text-transform: capitalize;
    margin-top: 10px;
    margin-bottom: 0px;
}
.producto{
    width: 100%;
    display: grid;
    grid-template-columns: 2.5fr 1fr; /* Dos columnas de igual tamaño */
    gap: 10%; /* Espacio entre las columnas */
    margin-bottom: 1vh;
}

.linea_punteada{
    width: 100%;
    height: 1px;
    border-bottom: 0.5px dashed #404040;; 
    
}
.valor_total{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    color:  #404040;
    font-size: 2.36vh;
    font-weight: 600;
    line-height: 132.5% ;
    margin-top: 4vh;
    margin-bottom: 4vh;
}