
.contenedorInputs{
    display: grid;
    grid-template-columns: 1fr;
    max-height: 50svh;
    height: fit-content;
    overflow: scroll;
    overflow-x: hidden;
    gap: 2vh;
    margin-top: 10%;
    width: 100%;
    padding: 0%;
    align-items: center;
}
 
.contenedorInputs::-webkit-scrollbar-thumb {
    background-color: #D74286;
}
.contenedorInputs::-webkit-scrollbar {
    width: 5px;
    background-color: #D9D9D9;
}

.contenedorInputs label{
    color: var(--Medium-Grey, #868189);
    text-align: center;
    font-family: Montserrat;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; 
    text-transform: capitalize;
}

.inputsJuego::placeholder{
    color: var(--color-Medium-Grey);
}

.inputsJuego{
    font-weight: 700;
    align-self: center;
    padding: 1svh 2svh;
    background:#D9D9D9 !important;
    height: 5vh !important;
    width: 100% !important;
    font-size: 2.2vh !important;
    color: var(--color-Dark-Grey);
}

.numeracion_inputs{
    width: 30%;

    color: var(--medium-grey, #868189);
    font-family: Source Sans Pro;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    text-transform: uppercase;
}