.contenedorNavegacion{
    margin: 0;
    text-align: center;
    align-items: center;
}
.flechaDerecha{
    transform: rotate(180deg);
}
.segundaPantallaPalabras{
    text-align: center;
    margin-left: 7%;
    margin-right: 7%;
    width: 80%;
}

.mensaje{
    color: #868189;
    font-family: "Montserrat";
    font-size: 2.23vh;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; 
}

.palabra{
    margin: 20svh 0;
    color: #868189;
    text-align: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    line-height: 150%; 
    height: fit-content;
    width: 100%;
    text-transform: capitalize;
}

.navegacionPalabrasGrid{
    display: grid;
}

.navegacionPalabrasGrid .contenedorNavegacion{
    display: grid;
    grid-template-columns: 15% 1fr 15%;

}