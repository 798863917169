.contenedor{
    width: 100%;
    height: 100%;
    padding: 0 12.82%;
    font-style: normal;
    font-family: 'Montserrat';
    display: flex;
    align-items: center;
    flex-direction: column;
}
.Titulo{
    color: var(--color-purple-dark);
    text-align: center;
    margin-top: 3.9vh;
    margin-bottom: 3.5vh;
    font-size: 2.36vh;
    font-weight: 700;
    line-height: 132.5% ;
    text-transform: capitalize;
}
.recibo{
    border-radius: 30px;
    width: 100%;
    /* height: 40.8vh; */
    padding: 10% 11.37% 7%;
}
.resumen, .recibo{
    background: #D9D9D9;
    color: #868189;
}
.tituloComprobante{
    color: #868189;
    text-align: center;
    font-size: 1.65vh;
    font-weight: 600;
    line-height: 189.286% ;
    margin-top: 0;
}
.tituloItem{
    color: #868189;
    font-size: 1.3vh;
    font-weight: 400;
    line-height:  240.909% ;
}
.valorItem{
    font-size: 1.4vh;
    font-weight: 600;
    line-height:220.833% ;
    text-transform: uppercase;
    margin-top: -1.3vh;
}
.resumen{
    padding: 6.2% 6.2% 10.6%;
    border-radius: 30px 30px 0 0;
    width: 100%;
}
.linea_punteada{
    width: 77.9%;
    height: 1px;
    border-bottom: 3px dashed #ffffff;
    margin: 0;
    background: #D9D9D9;
    margin-top: -2px;
}
.itemResumen{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    color: #868189;
    font-size: 1.4vh;
    font-weight: 600;
    line-height: 220.833% ;
    align-items: center;
    gap: 5%;
}
.triangulosFactura{
    width: 100%;
    background-image: url('../../public/assets/icon/PolygonFactura.png'); /* URL de la imagen del triángulo */
    background-repeat: repeat-x; /* Repetir solo en el eje X */
    height: 1vh;
    background-size:3% 100% ;
}
.botones{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2vh;
    width: 100%;
    
}