.bannerTop {
    height: 24.38%;
    background: linear-gradient(354deg, var(--color-Pink-Light) -78.59%, var(--color-purple-dark) 119.7%);
    box-shadow: 0px 5px 30px rgba(69, 34, 95, 0.33);
    display: flex;
    flex-direction: column;
    padding: 5%;
    /* Agregamos un margen inferior para que el título tenga más espacio */ 
}

.contentSesiones {
    overflow: auto;
}
.bannerTop div.space{
    display: flex;
    justify-content: space-between;
}

.ImagenSupercebro {
    background: transparent;
    position: absolute;
    z-index: 21;
    height: auto;
    width: 25%;
    /* Ajustamos la posición para que esté más abajo y no se solape con el título */
    top: 20vh; /* Aumenta el valor de top para posicionar la imagen más abajo */
    left: 3%;
}

.fondo{
    background: url(../../public/assets/images/fondoRuta.png);
    background-repeat:repeat-y ;
    background-size: 100%;
    height: fit-content;
    width: 100%;
    
}

p.subtitulo{
    color: #404040;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; 
    width: 100%;
    height: 10vh;
    padding-left: 35%;
    padding-right: 5%;
    text-align: start;
    display: flex;
    align-items: center;
    margin-top: 4vh;
}

.listaSesionesNew{
    position: relative;
    margin-top: 6vh;
    display: grid;
    padding: 1vh;
    grid-template-columns: 3fr 3fr 3fr;
    justify-items: center;
    height: fit-content;
}
.listaSesionesNew p{
    width: 100%;
    text-align: center;
    font-size: 5svw;
}
.listaSesionesNew p, .listaSesionesNew div{
    font-weight: 700;
}

@media only screen and (min-aspect-ratio: 7 / 8) {
    .listaSesionesNew p {
        font-size: 150%;
    }
}

.logo img{
    width: 80%;
}

.logo{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    z-index: 2;
}
