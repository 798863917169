.cardEntrenamiento{
  position: relative;
  width: 100%;
  color: #FFFFFF;
  display: grid;
  height: 100%;
  padding: 10% 5% 2% 5%;
  border-radius: 20px;
  align-content: space-between;
}

.label_entrenamiento{
  position: relative;
  z-index: 10;
  color: #FFF;
  font-family: 'Montserrat';
  font-size: 1.5em;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; 
  text-transform: uppercase;
  align-self: center;
  text-overflow: clip;
  max-width: 100%;
}
.label_entrenamiento strong{
  color: #FFF;
  font-weight: 800;
  line-height: 110%; 
  text-transform: uppercase;
  align-self: center;
  text-overflow: clip;
  max-width: 100%;
}

.button_test_inicial{
  background: linear-gradient(132deg, #D74286 0%, var(--color-purple-dark) 99.99%, var(--color-purple-dark) 100%);
  border-radius: 20px;
  width: 80%;
  height: 45px;
  color: #FFF;
  font-family: 'Source Sans Pro';
  font-size: 171.43%;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
}

.entrenamientoGrid{
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  row-gap: 2.53%;
  column-gap: 2.09%;
}

.entrenamientoGrid div:nth-child(n)::after{
  content: "";
  --tamano-flecha: 1.5em;
  position: absolute;
  bottom: 0;
  right: 0;
  background: url(../../../public/assets/images/flecha-entre.png) top left / var(--tamano-flecha) no-repeat;
  width: calc(5% + var(--tamano-flecha));
  height: calc(7% + var(--tamano-flecha));
}

.entrenamientoGrid div:nth-child(n)::before{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  opacity: 0.5;
}

.neurobics{
  grid-column: 1 / span 2;
  background: url(../../../public/assets/images/cardEntrenamiento/backNeurobics.png) center / cover no-repeat;
  align-content: flex-end;
}
.neurobics::before{
  background: linear-gradient(135deg,var(--color-blue-purple) 10%,var(--color-Pink-Light));
}

.superNutricion{
  background: url(../../../public/assets/images/cardEntrenamiento/backAlimentacion.png) center / cover no-repeat;
}
.superNutricion::before{
  background: linear-gradient(135deg,var(--color-light-blue),var(--color-green));
}

.superIngles{
  background: url(../../../public/assets/images/cardEntrenamiento/backIngles.png) center / cover no-repeat;
}
.superIngles::before{
  background: linear-gradient(135deg,var(--color-Pink-Light),var(--color-peach));
}

.entrenatusupercerebro{
  background: url(../../../public/assets/images/cardEntrenamiento/backSuperCerebros.png) center / cover no-repeat;
}
.entrenatusupercerebro::before{
  background: linear-gradient(135deg,var(--color-Pink-Light),var(--color-blue-purple));
}

.superCuboRubik::before{
  background: linear-gradient(135deg,var(--color-green),var(--color-red));
}
.superCuboRubik{
  background: url(../../../public/assets/images/cardEntrenamiento/backRubik.png) center / cover no-repeat;
}

.memoria::before{
  background: linear-gradient(135deg,var(--color-peach),var(--color-purple-dark));
}
.memoria{
  background: url(../../../public/assets/images/cardEntrenamiento/backMenteDorada.png) center / cover no-repeat;
}

.superlectura::before{
  background: linear-gradient(132deg,var(--color-blue-purple),var(--color-light-blue));
}
.superlectura{
  background: url(../../../public/assets/images/cardEntrenamiento/backLectura.png) center / cover no-repeat;
}

.entrenamientoDiv_block label{
  align-self: flex-start;
}
.entrenamientoDiv_block::before{
  background: var(--color-Light-Grey) !important;
  opacity: 0.8 !important;
}
.entrenamientoDiv_block::after{
  background: url(../../../public/assets/icon/candado.png) bottom 5% right 5% /  calc( 1.5 *var(--tamano-flecha)) no-repeat !important;
  width: calc(2*(5% + var(--tamano-flecha))) !important;
  height: calc(2*(7% + var(--tamano-flecha))) !important;
  margin: 5%;
}


/* Estilo para la venta de proxima emergente */
.modal{
  width: 85%;
  height: fit-content;
  min-height: 30svh;
  display: flex;
  flex-direction: column;
}

.modal::before{
  content: "";
  position: relative;
  width: 50%;
  height: 12svh;
  background: url(../../../public/assets/images/supercerebro/sc_chismoso.png) center / contain no-repeat;
  z-index: 3;
  margin-bottom: -1svh;
}

.cerebro{
  --color-Game-primary: var(--color-yellow) ;
  --color-Game-secondary: var(--color-peach) !important;
}

.cubo{
  --color-Game-primary: var(--color-blue-purple) ;
  --color-Game-secondary: var(--color-ligtht-blue) !important;
}

.modal img{
  width: 25%;
  aspect-ratio: 1 / 1;
  margin: 5% 0;
}

.modal p{
  color: var(--color-Medium-Grey);
}

.modal div{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  font-family: 'Montserrat' !important;
  align-items: center;
  padding-bottom: 5%;
}

.modal div label{
  --color-Game-secondary: var(--color-peach);
  margin: 0;
  width: 100%;
  padding: 27px 42px 16px;
  -webkit-text-fill-color: white;
  background: linear-gradient(-30deg,var(--color-Game-secondary),var(--color-Game-primary));
  border-radius: 15px;
  filter: drop-shadow(0px 1px 4px black);
}

/* Vista para escritorio */
@media screen and (min-width:1024px){
  .label_entrenamiento{
    font-size: 1.05em;
  }
  .label_entrenamiento strong{
    font-size: 1.05em;
  }
}

/* Vista para pantalla estilo tablet */
@media screen and (min-width:768px) and (max-width:1024px){
  .label_entrenamiento{
    font-size: 1.05em;
  }
  .label_entrenamiento strong{
    font-size: 1.05em;
  }
  
}

/* Ajustes para alinear el texto a la izquierda y uniformizar el tamaño de las letras */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .label_entrenamiento {
    font-size: 1.9em; /* Tamaño de fuente ajustado para uniformidad */
    line-height: 1.3; /* Ajuste de altura de línea */
    text-align: left; /* Alineación del texto a la izquierda */
    padding: 5% 3%; /* Espaciado adicional */
  }

  .label_entrenamiento strong {
    font-size: 1.1em; /* Mantener el tamaño de fuente similar al texto normal */
    font-weight: 700; /* Peso de fuente ajustado para distinguir sin exageración */
  }

  /* Ajustes adicionales para asegurar que las tarjetas se vean consistentes */
  .cardEntrenamiento {
    padding: 8% 4% 3% 4%; /* Ajuste de padding para mayor espacio en tabletas */
    text-align: left; /* Asegurar que el contenido esté alineado a la izquierda */
  }
}

