.toolbar_tamanoChat{
  height: 30svh ;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start ;
  padding: 10% 5%;
  background: linear-gradient(122deg, #45225F 25.42%, var(--color-Pink-Light) 171.98%);
}

.toolbar_tamanoChat .lineaTool{
  height: 0.5svh;
  width: 80%;
  background-color: white;
  margin: 1svh 10%;
  margin-bottom: 5svh;
}

.toolbar_tamanoChat p{
  /* correct */
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.5svh;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; 
  text-overflow: clip;
  height: fit-content;
  margin: 0 10.7%;
  transition-property: margin, font-size;
  transition-duration: 1s, 1s;
}

.toolbar_tamanoChat p.title{
  /* correct */
  font-size: 3.5vh;
  text-transform: uppercase;
  font-weight: bolder;
}

.defaultPage{
  display: flex;
  height: 4.63svh;
  width: calc(100% - 8.88svh);
  flex-direction: row;
  align-items: center;
  gap: 2.8svh;
}

.containImagen{
  /* correct */
  z-index: 3;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition-property: flex-direction, align-items;
  transition-duration: 1s, 1s;
}

.imagen{
  /* correct */
  background: url(../../public/assets/images/supercerebro//scDePieMirandoAlFrente.png) top / 300% 300% no-repeat, var(--color-purple-dark);
  height: 19.8svh;
  width: 19.8svh;
  border-radius: 100%;
  position: relative;
  z-index: 21;
  border: 4px solid white;
  transition-property: height, width;
  transition-duration: 1s, 1s;
}

.containImagen_active{
  /* correct */
  flex-direction: row;
  align-items: flex-start;
  margin-top: -5%;
}
.containImagen_active p{
  /* correct */
  font-size: 1.8svh;
  margin: 0;
  text-align: left;
  max-width: 56%;
  margin-top: 5%;
  transition-property: margin, font-size;
  transition-duration: 1s, 1s;
} 

.containImagen_active div{
  /* correct */
  height: 13.5svh;
  width: 13.5svh;
  transition-delay: 0.125s;
  transition-property: height, width;
  transition-duration: 1s, 1s;
}

.card{
  width: calc(100% - 5.8svh);
  height: 55svh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 3.5svh 3.25svh 3.4svh; 
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--color-Pink-Light) var(--color-Light-Grey);
}

.card div{
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.card p{
  color: var(--color-purple-dark);
  font-size: 1.66svh;
  line-height: 3.32svh;
  font-weight: 700;
  height: 4.75svh;
  text-align: center;
  align-items: center;
  display: flex;
  text-transform: uppercase;
}

.card label{
  color: var(--color-Medium-Grey);
  font-size: 1.66svh;
  line-height: 3.2svh;
  font-weight: 600;
  text-align: start;
  align-items: center;
  display: flex;
  text-transform: capitalize;
}

.card div:nth-child(2){
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card :nth-child(2) input{
  width: calc(100% - 3.7svh);
  height: 4.63svh;
  font-size: 1.9svh;
  text-align: center;
}

.card img{
  height: 1.32svh;
  width: 1.32svh;
}

.card :nth-child(5) p{
  width: calc(100% - 1.32svh - 2.79svh);
}

.card div:nth-child(3),
.card div:nth-child(4){
  border-bottom: 2px solid var(--color-Medium-Grey);
  max-width: calc(100% - 2.79svh);
}

.boxButton{
  width: calc(100% - 3.55svh);
  margin: 0% 1.775svh;
}

/**
 * Estilos para ConsultaIA
 */

/*-------------------------------------------*\ 
            ConsultaIA.css 
\*-------------------------------------------*/



.modal{
  /* correct */
  width: 83.646% !important;
  height: fit-content !important;
  padding: 3.32svh 8.46%;
  row-gap: 1svh;
}

.imagenChat{
  /* correct */
  height: 14svh;
  width: auto;
  aspect-ratio: 1 / 1;
}

.contenedorNeuronas{
  /* correct */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 40.34%;
  height: 5vh;
  margin-top: 6px;
  margin-bottom: 15px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1.5px;
}

.circuloNeuronas{
  /* correct */
  background: url(../../public/assets/icon/icon_neuronas_tab.svg) center / 80% 80%, 
    linear-gradient(180deg, var(--color-purple-dark) 0%, #D74286 100%);
  background-repeat: no-repeat;
  height: 100%;
  width: auto;
  aspect-ratio: 1;
  border-radius: 100%;
}
.neuronas{
  /* correct */
  text-align: center;
  font-family: 'Montserrat';
  font-size: 1.66svh;
  font-weight: 700;
  line-height: 285.714%;
  text-transform: capitalize;
  flex: 1;
  background: var(--color-degradado-vertical);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mensajeDefault{
  /* correct */
  position: relative;
  aspect-ratio: 4 / 1;
  height: calc(2.4svh + 2svh + 8.7svh);
  padding: 2svh;
  font-weight: 500;
  color: #45225F;
  background: url(../../public/assets/images/GloboChat.png) top left / contain no-repeat;
  font-size: 2.4svh;
  margin: 5%;
  margin-bottom: -2svh;
}

.buttonPreguntame{
  /* correct */
  width: fit-content !important;
  line-height: 100% !important;
  margin-bottom: 0px !important;
}

@media only screen and (min-aspect-ratio: 7/8) {
  .toolbar_tamanoChat{
    height: 20svh ;
  }
  
}