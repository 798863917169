.header {
    /* margin-bottom: -20px; */
    display: block;
    position: relative;
    -ms-flex-order: -1;
    order: -1;
    width: 100%;
    z-index: 10;
    background: var(--color-degradado-vertical);
}

.toolbar{
    box-shadow: 0px 5px 30px rgba(69, 34, 95, 0.33);
    background: var(--color-degradado-vertical);
    border-color: transparent;
    border-width: 0 !important;
    font-family: 'Montagu Slab';
    justify-content: space-between;
    padding: 0 5% !important;
    display: flex;
    flex-direction: row;
    margin-bottom: -10px;
}

.backButton{
    border: none;
    width: 20%;
    background: url(../../../public/assets/icon/flechaBlancaBack.svg) center 50%;
    background-repeat: no-repeat;
}

.header .toolbar h1{
    transform: translateX(-10%);
    flex: 1;
    text-align: center;
}

ol{
    padding: 0%;
}

.lista{
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 2%;
}

.card{
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 10px;
    height: auto;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 3%;
    margin-bottom: 5%;
}

.lista :nth-last-child(){
    margin-bottom: 0;
}

.card p{
    margin: 0;
    padding: 0;
    font-size: medium;
    height: fit-content;
}

.button{
    width: 100%;
    background: #D74286;
    padding: 2%;
    font-size: 2.2vh;
    font-weight: 200;
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
}

p{
    margin: 0;
}

.content{
    background-color: #FFFFFF;
    color: grey;
    padding: 2%;
    flex: 1;
    border-radius: 20px;
    z-index: 11;
}

.page{
    overflow: visible !important;
    z-index: 10;
    display: flex;
    flex-direction: column;
    background: transparent;
    height: -webkit-fill-available;
}