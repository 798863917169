.toolbar{
    height: 10.71% !important;
}

.containWindow_1{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2% 5%;
    gap: 2svh;
}

.frasePrincipal{
    font-weight: 600;
    font-size: 5svh;
    color: var(--color-purple-dark);
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 25px;
}


.frasePrincipal_codify{
    font-size: 290%;
    background: none;
    text-transform: uppercase;
    font-weight: 800;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}