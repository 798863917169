.selected{
  display: flex;
  align-items: center;  
  justify-content: center;
  width: 80%;
  height: 5.5vh;
  border-radius: 15px;
  background: var(--color-purple-dark) !important;
  color: white;
  text-align: center;
  font-family: 'Montserrat';
  font-size: 1rem;
  font-weight: 500;
  line-height: 100%; /* 250% */

}

.inputBox{
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  margin-top: 2.5svh;
  margin-bottom: 2.4svh;
  row-gap: 1svh;
  column-gap: .45svh;
  width: 37.3svh;
  height: 17.57svh;
}
  
.inputsOptions{
  height: 100%;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
