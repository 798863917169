/*
ETAPA REGISTRO
*/

.contenedor{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.contenedor > p{
  margin: 0 auto;
}

.titulo{
  padding: 7svh 0 3svh 0;
  color: var(--color-purple-dark);
  font-weight: 700;
  font-size: 2rem;
}

.subtitulo{
  color: var(--color-Medium-Grey);
  width: 70%;
  font-size: 1.15rem;
  text-align: center;
}

.datosForm{
  width: 85%;
  margin: auto;
}

.datosForm > div{
  background-color: var(--color-purple-dark);
  margin: 1rem 0;
  padding-top: 0.2rem;
  border-radius: 17px;
  border: solid 2px var(--color-purple-dark);
}
.datosForm > div > div{
  background-color: #e18db3;
  border-radius: 15px;
  margin-top: 0.2rem;
}

.datosForm label{
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
}

.datosForm input{
  background-color: white;
  font-size: 1rem;
  padding: 0.9rem;
}

.datosBoton{
  margin: auto 5% 10% 5%;
  width: auto;
}

/*
ETAPA ESPERA
*/

.infoEspera{
  display: grid;
  grid-template-rows: auto auto;
  gap: 2svh;

  width: 60%;
  margin: 0 auto;

  padding-bottom: 3svh;
}
.infoEspera p{
  text-align: center;
  font-size: 1.9svh;
  font-weight: 700;

  padding: 1rem 1.5rem;
  margin: 0;

  border-radius: 1rem;
}
.infoEspera p span{
  font-size: 2.5svh;
  font-weight: 700;
  color: white;
}

.puntajeEspera{
  background-color: var(--color-Pink-Light);
  color: var(--color-purple-dark);
}

.totalEspera{
  background-color: var(--color-purple-dark);
  color: var(--color-Pink-Light);
}


.mensajeEspera{
  width: 60%;
  padding-bottom: 4svh;

  font-size:2.8svh;
  font-weight: 700;
  color: var(--color-purple-dark);

  text-align: center;
}
.mensajeEspera span{
  font-size: inherit;
  font-weight: 800;
  color: var(--color-Pink-Light);
}

.imagenEspera{
    overflow: hidden;
    width: 60svw;
    height: 60svw;

    max-width: 30svh;
    max-height: 30svh;

    margin: 4svh auto;
    border-radius: 999999px;
    background-color: var(--color-purple-dark);
}

@keyframes fadeInOut {
  0%{
    opacity: 0;
  }
  20%{
    opacity: 0;
  }
  40%{
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

.indicadorEspera span{
  font-size: 2rem;
  color: var(--color-Pink-Light);
  animation: fadeInOut 1.6s ease-in-out 0s infinite;
}

.indicadorEspera span:nth-child(1){animation-delay: 0.2s; }
.indicadorEspera span:nth-child(2){animation-delay: 0.4s; }
.indicadorEspera span:nth-child(3){animation-delay: 0.6s; }

.nombreEspera{
  background-color: var(--color-Pink-Light);

  border-radius: 17px 17px 0 0;

  padding: 1rem 1.2rem 0.3rem 1.2rem;
  margin: 0 20px 0 20px;
  
  font-weight: 600;
  color: white;
  text-align: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cantidadEspera{
  border: 1px solid var(--color-Medium-Grey);
  border-radius: 17px;

  padding: 1rem;

  background-color: #F6F4F7;

  font-size: 2rem;
  font-weight: 900;
  color: var(--color-Medium-Grey);

  text-align: center;
}

/*
ETAPA ESPERA SIGUIENTE JUEGO
*/

.tituloEsperaRanking{
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-purple-dark);

  justify-self: left;

  padding-top: 2svh;
}

.esperaRanking{
  margin: 2svh auto auto auto;

  display: grid;
  gap: 1.8svh;

  
  color: var(--color-purple-dark);

  width: 80%;
  text-align: center;
}

.rankingLI{
  display: flex;
  align-items: center;

  font-size: 2svh;
  color: var(--color-Medium-Grey);

  padding: 2svh 1rem;
  max-width: 80vw;

  background-color: white;

  border-radius: 0.6em;

  -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.4);
  box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.4);
}

.rankingLIJugador{
  font-size: 3svh;
  color: white;

  background-color: var(--color-Pink-Light);

  max-width: 79vw;
  width: 97%;
  padding: 2.2svh 1rem;

  justify-self: center;
}

.rankingLIPosicion{
  font-size: 3svh;
  font-weight: 700;
  color: inherit;

  margin: 0 4%;
}

.rankingLINombre{
  font-size: inherit;
  color: inherit;

  font-weight: 500;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 3%;
}

.rankingLITotal{
  font-size: inherit;
  color: inherit;
  margin:0 4% 0 auto;

  font-weight: 500;
}

.rankingLIJugador .rankingLIPosicion{
  font-size: 4svh;
  font-weight: 700;
}

/*
ETAPA FINALIZACION
*/

.finalRanking{
  font-weight: 700;
  font-size: 3.5svh;
  color: var(--color-purple-dark);
  text-align: center;

  margin: auto;
}

.finalPosicion{
  width: 35svh;
  border-radius: 999999px;

  background-color: var(--color-purple-dark);

  margin: 4svh auto;
}

.finalPosicion > div{
  padding-bottom: 100%;

  position: relative;
}

.finalPosicion > div > div{
  display: flex;
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  align-items: center;
  justify-content: center;

  font-size: 18svh;
  font-weight: 700;
  color: white;
}

.finalIntegrantes{
  background-color: var(--color-purple-dark);

  font-size: 3.5svh;
  font-weight: 700;
  color: white;

  width: fit-content;
  border-radius: 0.8svh;
  padding: 0 5vw;

  margin: 0.5svh auto;
}
