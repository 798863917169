/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
*{
  font-family: 'Montserrat'; 
  font-weight: 400;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  color: var(--color-Dark-Grey);
}

p {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  margin-bottom: 0 !important;
}

input{
  color: var(--color-Medium-Grey);
  background-color: var(--color-Light-Grey);
  border: 2px solid var(--color-Medium-Grey);
}

:root {
  --color-red:#CB4141;
  --color-Dark-red:#A52929;
  --color-light-blue:#6491D4;
  --color-Dark-blue:#336EB5;
  --color-green:#77B15C;
  --color-Dark-green:#4A872A;
  --color-yellow:#FFB21C;
  --color-Dark-yellow:#DB7119;
  --color-peach:#FF927A;
  --color-Dark-Grey:#404040;
  --color-Medium-Grey:#868189;
  --color-Light-Grey:#D9D9D9;
  --color-White-Grey:#F6F4F7;
  --color-blue-purple:#5755CA;
  --color-Dark-blue-purple:#4040B5;
  --color-Pink-Light: #D74286;
  --color-Dark-Pink: #BF2564;
  --color-purple-dark: #45225F;
  --color-segundario: #96327A;
  --color-degradado-vertical:linear-gradient(122deg, #45225F 25.42%, #D74286 171.98%);
  --color-degradado-vertical-2:linear-gradient(180deg, #D74286 0%, #45225F 100%);
  --color-degradado-horizontal: linear-gradient(132 deg, #D74286 0%, #45225F 99.99%, #45225F 100%);
}

body {
  width: 100svw;
  height: 100svh;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  overscroll-behavior-y: contain;
} 

body.entranmientoBody{
  --ion-background-color: var(--color-Pink-Light);
}
#root{
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#App{
  width: 100%;
  height: 100dvh;
  contain: layout size style;
  z-index: 10;
  display: flex;
  flex-direction: column;
  position: relative;

}
/**
 *  css para el page que va en cada componente  
*/
#page{
  /* para que tome el tamaño restante de la pantalla */
  height: calc(92.3%);
  display: flex;
  flex-direction: column;
}

/**
 *  id default para los toolbar
*/
#toolbar_default{
  background: linear-gradient(122deg, #45225F 25.42%, var(--color-Pink-Light) 171.98%);
  height: 11.61%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title_default{
  margin: 0;
  transform: none;
  color: #FFF;
  font-family: 'Montserrat';
  font-size: 2.37svh;
  font-style: normal;
  font-weight: 700;
  line-height:  200%;
  text-transform: uppercase;
}

/**
 *  css para el content por default que va en cada componente  
*/
#content_default{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background: #FFF;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
  /* para que se monte sobre el toolbar de la page */
  margin-top: -1.777svh;
}

/**
 *  id default para los botones rosados,
 *  Recomendacion: tenga en cuenta de colocar un contenedor que sea del ancho requerido
 *                 esto porque el boton se adapta al 100% del contenedor padre
 */
#button_rosado_default{
  border-radius: 15px;
  background: var(--color-Pink-Light);
  color: white;
  width: 100%;
  height: fit-content;
  font-size: 2.37svh;
  font-weight: 700;
  line-height: 110%; 
  margin-bottom: 2.36svh;
  border: none;
  text-transform: uppercase;
  padding: 2% 5%;
}

/**
 *  id default para los botones que tienen bordes rosados y son blancos,
 *  Recomendacion: tenga en cuenta de colocar un contenedor que sea del ancho requerido
 *                 esto porque el boton se adapta al 100% del contenedor padre
 */
#button_bordes_default{
  background: white;
  width: 100%;
  height: 17.14%;
  color: var(--color-Pink-Light);

  font-weight: 700;
  font-size: 2.37svh;
  line-height: 222.222%;

  border-radius: 15px;
  margin-bottom: 2.36svh;
  border: 2px solid var(--color-Pink-Light);
}

#modal_cortina_default{
  background-color:rgba(0, 0, 0, 0.3);
  z-index: 112;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#modal_default{
  background-color: rgba(255, 255, 255, 1);
  width: 70%;
  height: 60svh;
  position: fixed;
  z-index: 114;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#toast{
  width: 100%;
  height: 10vh;
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  font-size: 17px;
  left: 50%;
  transform: translateX(-50%);
  text-transform: capitalize;
}

#toast.show_center {
  bottom: 50%;
  height: 30svh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  width: auto;
  aspect-ratio: 1/1;
  visibility: visible;
  -webkit-animation: fadein-center 0.5s, fadeout 0.5s 2.5s;
  animation: fadein-center 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein-center {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 50%; opacity: 1;}
}

@keyframes fadein-center {
  from {bottom: 0; opacity: 0;}
  to {bottom: 50%; opacity: 1;}
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

/* estilos utilizados en la APP basicos como el centrar */
.centrar{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.cubrir{
  width: 100%;
  height: 100%;
}

.desaparecer{
  display: none !important;
}



.swiper {
  width: 100%;
  height: fit-content;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

@media only screen and (min-aspect-ratio: 7/8) {
  *{
    font-size: 2svh;
  }
  body{
    background: linear-gradient(180deg, #45245F 0%, #D74285 100%);
    background-repeat: no-repeat;
    height: 100dvh;
    width: 100svw;
  }
  #App {
    
    max-width: 70vh;
    
  }
  body #supercerebro-fondo{
    position: fixed;
    left: -15%;
    top: 10.6vh;
    background-image: url("../../public/assets/images/supercerebro/SuperCerebroSenalando.png");
    background-size: 100% 100%;
    height: 120.23vh;
    width: 120vh;
    background-color: transparent;
    z-index: 2;
    display: block;
  }
  body #logo-fondo{
    position: fixed;
    left: 79.5%;
    top: 58.6vh;
    background-image: url("../../public/assets/images/Logo-blanco-fondo.png");
    background-size: 100% 100%;
    height: 14.23vh;
    width: 17vh;
    z-index: 1;
    background-color: transparent;
  }
  body #title-fondo{
    position: fixed;
    left: 77.8%;
    top: 76.9vh;
    background-image: url("../../public/assets/images/titulo-supercerebros.png");
    background-size: 100% 100%;
    height: 6.5vh;
    width: 25.14vh;
    z-index: 1;
    background-color: transparent;
  }
  body #nube-derecha-fondo{
    position: fixed;
    right:0;
    bottom:0;
    background-image: url("../../public/assets/images/nube-derecha.png");
    background-size: 100% 100%;
    height: 78.8vh;
    width: 25%;
    z-index: 0;
    background-color: transparent;
  }
  
  body #nube-izquierda-fondo{
    position: fixed;
    left:0;
    top:0;
    background-image: url("../../public/assets/images/nube-izquierda.png");
    background-size: 100% 100%;
    height: 47.7vh;
    width: 15.9%;
    z-index: 0;
    background-color: transparent;
  }
  body #arroba-fondo{
    position: fixed;
    right:7%;
    top:89.3vh;
    background-image: url("../../public/assets/images/arroba-supercerebros.png");
    background-size: 100% 100%;
    height: 2.3vh;
    width: 15.2%;
    z-index: 0;
    background-color: transparent;
  }
  body #logo-redes-fondo{
    position: fixed;
    right:22.2%;
    top:88.1vh;
    background-image: url("../../public/assets/images/logos-redes.png");
    background-size: 100% 100%;
    height: 5.3vh;
    width: 2.3%;
    z-index: 0;
    background-color: transparent;
  }
  #App {
    overflow: visible !important;
    z-index: 10;
  }
  #modal_default{
    max-width: 70svh;
  }
}

