@keyframes montar {
  0%{
    transform: translateY(100%);
  }
  100%{
    transform: translateY();
  }
}
/*------------------------------------
estilos tablero de juego
*/
.tableroContenedor{
  display: grid;
  grid-template-rows: auto min-content;
}
.memory-game {
  display: grid;
  gap: 0.4rem;
  transition: padding 0.5s;
}
.grid4{
  grid-template-columns: repeat(2, 1fr);
  padding: 20% 20%;
}
.grid6{
  grid-template-columns: repeat(2, 1fr);
  padding: 5% 20%;
}
.grid8{
  grid-template-columns: repeat(2, 1fr);
  padding: 5% 25%;
}
.grid12{
  grid-template-columns: repeat(3, 1fr);
  padding: 5% 20%;
}
.grid16{
  grid-template-columns: repeat(4, 1fr);
  padding: 5% 5%;
}

@media (max-aspect-ratio: 10/16) {
  .grid4{
    padding: 20% 10%;
  }
  .grid6{
    padding: 5% 10%;
  }
  .grid8{
    padding: 5% 15%;
  }
  .grid12{
    padding: 5% 10%;
  }
  .grid16{
    padding: 5% 5%;
  }
}
@media (max-width: 300px){
  .grid16{
    gap: 0.2rem;
    padding: 5% 5%;
  }
}

/*-------------------------------------
estilo cartas
*/
.cardParejas {
  border-radius: 15px;
} 
.card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s;
  border-radius: 15px;
}
.cardParejas.flipped .card-inner {
  transform: rotateY(180deg);
}
.card-front, .card-back {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.card-back {
  transform: rotateY(180deg);
  background-color: white;
}
.card-cover {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.degradado1{
  background: linear-gradient(0deg, #81225E  0%, var(--color-purple-dark) 100%);
}
.degradado2{
  background: linear-gradient(0deg, #9E2A6B 0%, #81225E 100%);  
}
.degradado3{
  background: linear-gradient(0deg, #BA3378  0%, #9E2A6B 100%);
}
.degradado4{
  background: linear-gradient(0deg, var(--color-Pink-Light) 0%, #BA3378  100%);
}
.cardParejas img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
/*-------------------------------------
estilo decoraciones tablero
*/
.topInfoContainer{
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.5rem;
  align-items: center;
  background-color: #D74286;
  color: white;
  font-variant-numeric: tabular-nums;
  padding: 0.2rem 0.5rem;
  border-radius: 0.6rem;
}
.bottomInfoContainer{
  display: flex;
  margin: 0 0.5rem 1.5rem 0.5rem;
  color: #45225F;
  font-weight: 600;
  justify-content: center;
}
.opaqueFinal{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFA;
  border-radius: 15px 15px 0 0;
  display: flex;
  flex-direction: column-reverse;
}

/*-------------------------------------
estilos popOver
*/
.popOverMensaje{
  text-align: center;
  color:  #868189;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 1rem 1rem 0 1rem;
}
.popOverMensaje span{
  color: var(--color-Pink-Light) !important;
  font-weight: 600;
  font-size: 1em;
  font-variant-numeric: tabular-nums;
}
.popOverBotones{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 6vw;
  margin-top: 2rem;
  align-items: center;
  height: fit-content;
}
.linkSalir{
  color: #D74286;
  text-decoration: underline;
  cursor: pointer;
}

.coverturaSiguiente{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  display: flex;
  padding: 10%;
}
.contenedorSiguiente{
  display: grid;
  grid-template-rows: auto auto auto;
  justify-items: center;
  gap: 2rem;
  margin: auto;
  width: 80%;
  min-width: fit-content;
  padding: 3rem 10% 2rem 10%;
  background-color: white;

  border-radius: 3rem;

  -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.4);
  box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.4);

  animation: montar 0.4s ease-out;
}
.popOverNextMensaje{
  text-align: center;
  font-size: 7vmin;
}

@media only screen and (min-aspect-ratio: 7 / 8){
  .popOverNextMensaje {
    font-size: 5.8vh;
  }
}
 
/*-------------------------------------
estilos carteles
*/
.cartelPreSubEtapa{
  position: absolute;
  top: 37%;
  z-index: 3;

  width: 80%;
  font-size: 8vmin;
  font-weight: bolder;
  color: var(--color-purple-dark);
  padding: 2rem;

  -webkit-box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.25);

  border-radius: 1rem;
  background-color: rgba(255,255,255,0.8);

  transition: transform 0.3s ease-in-out;
}

.ocultoAbajo{
  transform: translateY(70vh);
}
.ocultoArriba{
  transform: translateY(-70vh);
}
