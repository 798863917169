*, *::before{box-sizing: border-box;}

.chatIA_banner-default{
  /* correct */
  flex: 1;
  width: 100%;
  padding: 5% ;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.chatIA_banner{
  /* correct */
  background: white;
  width: 100%;
  position: relative;
  border-radius: 16px;
  left: 0;
  padding: 2%;
  z-index: 2;
  box-shadow: 1px 2px 9px black;
  display: flex;
  align-items: flex-start;
}

.chatIA_banner::after{
  /* correct */
  content: "Super Cerebro";
  font-family: "Montserrat";
  font-size: 2svh;
  font-weight: 700;
  color: var(--color-purple-dark);
  position: relative;
  top: 50%;
  margin: 0 5%;
}

.chatContent{
  /* correct */
  width: 100%;
  padding: 3% 7%;
  display: flex;
  flex-direction: column;
  row-gap: 2%;
  overflow-y: scroll;
  height: fit-content;
  scrollbar-color: var(--color-Pink-Light) var(--color-Light-Grey);
  scrollbar-width: thin;
}

.globo-inicial{
  width:'100%';
  height:'5vh';
  margin:'10% 0%';
}

.globo{
  /* correct */
  position: relative;
  text-transform: none;
  font-size: 1rem;
  border-radius: var(--borde);
  padding: var(--borde);
  --borde: 1em;
  font-family: "Noto Sans";
  z-index: 1; 
  background-color: white;
  filter: drop-shadow(0px 0px 3px var(--color-purple-dark)) ;
}

.globo.i{
  /* correct */
  font-size: 1rem;
  width: 90%;
  margin-left: 10%;
  margin-top: 5%;
}

.globo::before{
  /* correct */
  content: '';
  position: absolute; 
  z-index: -1;
  width: var(--colita);
  height: var(--colita);
}

.selectPredeterminada{
  position: relative;
  border-bottom: 1px var(--color-purple-dark) solid;
  width: 90%;
  padding-bottom: 2%;
  padding-top: 4%;
  font-size: 2svh;
}

.selectPredeterminada p::after{
  content: "";
  position: absolute;
  left: -10%;
  top: 50%;
  height: 2svh;
  aspect-ratio: 1/1;
  border-radius: 100%;
  box-shadow: inset 0px 0px 4px var(--color-purple-dark);
}

.selectPredeterminada p{
  font-weight: 500;
  color: var(--color-purple-dark);
}

.globo.abajo-izquierda{
  border-radius: 0 20px 20px 20px;
  margin-left: 0%;
  color: black;
}
.globo.globo.abajo-derecha{
  border-radius: 20px 0px 20px 20px; 
}

.globo.colita-izquierda::before{
  /* correct */
  --colita: 50px;
  left: 10px;
  bottom: calc( var(--colita) / -1.5);
  border-radius: 0 0 10em 0;
}

.inputChat{
  width: 90% !important;
  height: fit-content;
  max-width: 90%;
  background-color: var(--Medium-Grey, #F3F3F3);
  border:transparent;
  border-radius: 0.9375rem;
  padding: 2% 5%;
  height: fit-content;
  word-wrap: break-word;
}

ion-input.inputChat{
  height: fit-content !important;
}

.texto_aviso{
  color: var(--color-purple-dark);
  text-align: center;
  font-family: 'Montserrat';
  font-size: 1.89svh;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4375rem; 
}

.modal-mensaje-home{
  /* padding-bottom: 2vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35vh 5vh 35vh 5vh;
  /* border-radius: 100%; */
  left: 0%;
  /* margin-top: 45%; */
  width: 100%;
  height: 100%;
}

ion-modal.centrar-modal::part(content){
  display: flex;
  align-items: center;
  width: 80%;
  border-radius: 30px;
  height: fit-content;
  max-height: 80vh;
  padding: 5%;
  /* justify-content: flex-end; */
}