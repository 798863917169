.switchContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  justify-content: center;
  margin: 1em;
}

.switch {
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.switch.active {
  background-color: #333;
}

.circle {
  width: 25px;
  height: 25px;
  background-color: var(--color-Pink-Light);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  transition: left 0.3s ease;
}

.switch.active .circle {
  left: 33px;
}

.label {
  font-size: 16px;
  color: #FFF;
}

.buttonEmpezar {
  padding: 4%;
  background: #D74286;
  border-radius: 15px;
  width: 4rem;
  height: fit-content;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 2.36svh;
  color: #ffffff;
}

.preguntaDecimal {
  display: flex;
  flex-direction: row;
  gap: 0.5em; /* Ajustar según sea necesario para espacio entre los números */
}
