:root{
  --color-Game-primary: #D74286;
  --color-Game-secondary: #FFB21C;
}

.nombres{
    --color-Game-primary: #6491D4 !important;
    --color-Game-secondary: #77B15C !important;
}

.palabras{
    --color-Game-primary: #77B15C !important;
    --color-Game-secondary: #6491D4 !important;
}

.selectorContenedor{
    display: grid;
    grid-template-columns: auto 6rem;
    width: 100%;
    align-items: center;
    margin-bottom: 3%;
}
.selector{
    background: #F6F4F7;
    width: 95%;
    height: fit-content;
    padding: 1rem;
    margin-right: 5%;
    border-radius: 15px;
    border: 2px solid #C0BCC2;
    grid-area: 1/1/1/3;
}
.selector >p {
    color: #868189;
    font-size: larger;
}

.selectorInputContenedor{
    grid-column: 2;
    grid-row: 1;
}
.selectorInput{
    border: 2px solid var(--color-Medium-Grey);
    background-color: var(--color-Light-Grey);
    width: 100%;
    padding-top: 95%;
    position: relative;
    border-radius: 9999px;
}
.selectorInput input{
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(var(--color-Game-secondary),var(--color-Game-primary));
    color: transparent;
    background-clip: text;
    font-weight: 900;
    font-size: xxx-large;
    text-align: center;
}
.cortina{    
    position: fixed;
    top: 0;
    width: 100%;
    height: 100svh;
    background-color: var(--color-Dark-Grey);
    opacity: 0.5;
    right: 0;
    z-index: 2000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.box{
    position: fixed;
    width: 70%;
    height: fit-content;
    min-width: 39svh;
    min-height: 52svh;
    background-color: transparent;
    z-index: 2001;

    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}


.box .titulo{
    width: 100%;
    height: fit-content;
    padding: 3%;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.7svh;
    font-weight: 600;
    background: linear-gradient(90deg,var(--color-Game-secondary),var(--color-Game-primary));
    border-radius: 20px;
    color: white;
}

.contentOptions{
    background-color: white;
    border-radius: 20px;
    overflow-y: auto;   
    height: fit-content;
    max-height: 71svh;
    width: 100%;
    display: grid;
    padding: 7%;
    gap: 7%;
    grid-template-columns: 1fr 1fr;
    margin-top: 5%;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: var(--color-Game-primary) transparent;
}


.contentOptions button:nth-child(n){
    background-color: var(--color-Game-primary);
    aspect-ratio: 1 / 1;
    border-radius: 20px;
    border: none;
    box-shadow: none;
    padding: 10%;
}

.contentOptions button:last-child:nth-child(odd){
    grid-column: 1 / span 2;
    width: 45%;
    align-self: center;
    justify-self: center;
}

.contentOptions button:nth-child(n) p{
    width: 100%;
    height: fit-content;
    text-align: center;
    color: white;
    font-weight: 900;
    font-size: -webkit-xxx-large;
}

.seleccionado{
    background: linear-gradient(135deg,var(--color-Game-secondary),var(--color-Game-primary));
}