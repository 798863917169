.neurona{
    grid-column: 2;
    cursor: pointer;
}
.neurona text{
    font-size: 20px;
    line-height: 2.5rem;
    font-weight: 900;
}
.conexion{
    cursor: initial;
}

.desactivada{
    filter: drop-shadow( 0px 0px 7px #C0BCC2 );
}

.completada{
    filter: drop-shadow(-1px 3px 5px var(--color-Pink-Light));
}

.actual{
    filter: drop-shadow(0px 0px 20px yellow);
    animation: oprimeme 1s ease-in-out infinite;
    fill: #512461;
}

@keyframes oprimeme {
    10%{
        filter: drop-shadow(0px 0px 20px yellow);
    }
    50% {
        filter: drop-shadow(0px 0px 50px #7B96B8);
    }
    90%{
        filter: drop-shadow(0px 0px 20px yellow);
    }
}

.flipped{
    transform: scaleX(-1);
}
.flipped text{
    transform: scaleX(-1);
}

.continuar{
    font-size: 3svw;
    color: dark-gray;

    align-self: center;
    margin-left: 15%;

    padding: 5% 10%;
    background-color: rgb(255, 217, 0);
    border-radius: 10%/30%;

    /* animation: oprimeme 1s ease-in-out infinite; */
    filter: drop-shadow(0px 0px 5px #C0BCC2);
    cursor: pointer;
}

@media only screen and (min-aspect-ratio: 7 / 8) {
    .continuar {
        font-size: 90%;
    }
}
