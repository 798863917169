/**
 * Estilos para Componentes Memoria y agilidad Mental
 * estos componentes contienen los juegos que pertenecen a cada uno de los tipos de juegos
 * tipos juegos: Agilidad mental y memoria
 */

/*-------------------------------------------*\ 
            AGILIDAD MENTAL.css 
\*-------------------------------------------*/


 /*
 *  contenedor de los 3 juegos de calculo
 */
 .contenedor_cards_juegos{
    height: 100%;
    width: 100%;
    padding: 9.74% 19.23% 0 19.23%;
    font-family: 'Montserrat';
    font-style: normal;

    /* coloca el fondo de las neuronas grises*/
    background: url(/public/assets/componentes/Juegos/Neuronas_fondo.svg);
    
    /*para ubicar cada card de los juegos uno debajo de los otros*/
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*
 *  se aplica a las 3 cards que tiene el componte Agilidad Mental
 */
.card_calculo_matematico, .card_calculo_fecha, .card_neural_trainer{
    border-radius: 20px;
    margin-bottom: 1.54svh;
    padding: 2.96svh 11.66% 0 7.9%;
    color: white;
    text-decoration: none;

    /*altura con respecto a la del padre sin contar el padding */
    height: 28.78%;
    width: auto;

    /*para que mantenga una dimension de width/ height = 1.26*/
    aspect-ratio: 1.26;

}

/**
 *  background personalizado para card calculo matematico
 */
.card_calculo_matematico{
    /*la imagen de background ya viene recortada entonces solo es ubicarla abajo y a la derecha*/
    background:url(/public/assets/componentes/Juegos/calculo_matematico_recortado.svg),
    linear-gradient(147deg, #FA887B 3.24%, var(--color-Pink-Light) 108.15%);
    background-repeat: no-repeat;
    background-position: bottom 0 right 0,0 0;

    /*para que tome la imagen tome de ancho:auto y de alto 70% del alto, y para el linear-gradiente: contain*/
    background-size: auto 70%, contain;
}

/**
 *  background personalizado para card calculo de la fecha
 */
.card_calculo_fecha{
    /*la imagen de background ya viene recortada entonces solo es ubicarla abajo y a la derecha*/
    background:url(/public/assets/componentes/Juegos/calculo_fecha_recortado.svg),
    linear-gradient(147deg, #9F42BF 3.24%, var(--color-Pink-Light) 97.51%);
    background-repeat: no-repeat;
    background-position: bottom 0 right 0,0 0;

    /*para que tome la imagen tome de ancho:auto y de alto 70% del alto, y para el linear-gradiente: contain*/
    background-size: auto 70%, contain;
    opacity: 0.5;
}

/**
 *  background personalizado para card de neural trainer
 */
.card_neural_trainer{
    /*la imagen de background ya viene recortada entonces solo es ubicarla abajo y a la derecha*/
    background:url(/public/assets/componentes/Juegos/neural_cortado.svg),
    linear-gradient(156deg, #FFB21C 29.42%, var(--color-Pink-Light) 117.73%);
    background-repeat: no-repeat;
    background-position: bottom 0 right 0,0 0;
    
    /*para que tome la imagen tome de ancho:auto y de alto 70% del alto, y para el linear-gradiente: contain*/
    background-size: auto 70%, contain;

}
/**
 *  estilos para el titulo de cada una de las card
 *  Advertencia: si cambia el tipo de etiqueta html, no se aplican las clases
 */
.card_calculo_fecha h3, .card_calculo_matematico h3, .card_neural_trainer h3,
.card_sc_dice h3, .card_parejas h3, .card_memoria_nombres h3, .card_memoria_palabra h3{
    margin: 0;
    color: #FFF;
    font-size: 1.9svh;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 134.375%;

}

/*--------------------------------------*\
                MEMORIA.CSS
\*---------------------------------------*/

.contenedor_cards_memoria{
    height: 100%;
    width: 100%;
    padding: 12.8% 5.13% 0 5.13%;
    font-family: 'Montserrat';
    font-style: normal;

    /* coloca el fondo de las neuronas grises*/
    background: url(/public/assets/componentes/Juegos/Neuronas_fondo.svg);

}
/*
 *  Va dentro del contenedor_cards_memoria
 *  El objetivo es meter los juegos es un grid 
 */
.contenedor_grid{
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 8.5px)); /* Restando el doble del grid-gap */

    /* se le da una altura a cada fila en el grid*/
    grid-template-rows: 21.75% 38.1% 21.75%; 
    grid-gap: 17px;
    width: 100%;
    height: 100%;

}

/*
 *  Estilos para la card de supercerebros dice y memoria parejas
 *  las dos tienen una forma igual horizontal con igual tamaño
 */
.card_sc_dice, .card_parejas{
    border-radius: 20px;
    color: #FFF;
    padding: 25px 15px 0 15px;

    /* quita lineado del texto de la etiqueta Link de React*/
    text-decoration: none;

    /*se toman las dos columnas del grid, para que tome todo el ancho posible*/
    grid-column: 1 / 3;
    
    /* para mantener las dimensiones de manera responsive donde width/ height =2.482 */
    aspect-ratio: 2.482;
    height: 100%;

    /*se justifica centrado con el objetivo de que en dispositivos grandes donde el grid sea mas ancho que la card, esta se centre*/
    justify-self: center;

    
}

/*
 *  Estilos para el card que linkea al juego de scDice
 */
.card_sc_dice{
    /*Tiene dos backgrounds, una imagen y un gradiente*/
    background: url(/public/assets/componentes/Juegos/sc_recortado.svg),
    linear-gradient(130deg, #5755CA 8.51%, var(--color-Pink-Light) 125.89%);
    background-repeat: no-repeat;

    /*ubica la imagen totalmente a la derecha y abajo, el gradiente se deja en 0*/
    background-position: bottom 0 right 0, 0 0;

    /*la imagen va a tener un % de alto respecto a la actura de la card, el ancho se ajusta automatico*/
    background-size: auto 84.4%,contain;
}

/*
 *  Estilos para el card que linkea al juego de memoria parejas
 *  Uicada en el grid en la columna:1 y 2, fila:3
 */
.card_parejas{
     /*Tiene dos backgrounds, una imagen y un gradiente*/
    background:url(/public/assets/componentes/Juegos/parejas_recortado.svg),
    linear-gradient(321deg, #5755CA -1.25%, #4E84DC 97.64%);   
    background-repeat: no-repeat;
    
    /*ubica la imagen totalmente a la derecha y abajo, el gradiente se deja en 0*/
    background-position: bottom 0 right 0,0 0; 

    /*la imagen va a tener un % de alto respecto a la actura de la card, el ancho se ajusta automatico*/
    background-size: auto 84.4%,contain;
}

/*
 *  Estilos para el card que linkea al juego de memoria palabras
 *  
 */
.card_memoria_nombres, .card_memoria_palabra{
    /*se ajusta al ancho de su area en el grid y se da unas dimensiones de width/ heigh = 0.676. Esto para que sea mantenga sus dimensiones en una relacion*/
    width: auto;
    height: 100%;
    aspect-ratio: 0.676;

    border-radius: 20px;
    color: #FFF;
    padding: 25px 15px 0 15px;
    text-decoration: none;

}

/*
 *  Estilos para el card que linkea al juego de memoria palabras
 *  Uicada en el grid en la columna:2, fila:2
 */
.card_memoria_palabra{
    /*se justifica al inicio para que en dispositivos donde el area del grid sea más grande al tamaño de la card, este tienda a ubicarse al centro de la pantalla*/
    justify-self: start;

     /*Tiene dos backgrounds, una imagen y un gradiente*/
    background: url(/public/assets/componentes/Juegos/Abc_recortado.svg),
    linear-gradient(148deg, #4E84DC 40.57%, #77B15C 129.02%);
    background-repeat: no-repeat;
    
    /*ubica la imagen totalmente a la derecha y abajo, el gradiente se deja en 0*/
    background-position: bottom 0 right 0,0 0;
    background-size: 80.2% auto,contain;
}

/*
 *  Estilos para el card que linkea al juego de memoria nombres
 *  Uicada en el grid en la columna:1, fila:2
 */
.card_memoria_nombres{
    /*se justifica al final para que en dispositivos donde el area del grid sea más grande al tamaño de la card, este trate de centrarse*/
    justify-self: end;

     /*Tiene dos backgrounds, una imagen y un gradiente*/
    background: url(/public/assets/componentes/Juegos/rostro_recortado.svg),
    linear-gradient(317deg, #4A86DF -10.06%, #74AE64 85.61%);
    background-repeat: no-repeat;

    /*ubica la imagen totalmente a la derecha y abajo, el gradiente se deja en 0*/
    background-position: bottom 0 right 0,0 0;
    background-size: 80.2% auto,contain;
    
}


/*---------------------------*\
    Toolbar.css: Memoria y agilida 
\*---------------------------*/

/*
 * toolbar para el componente memoria y agilidad mental
 */
.toolbar_tipos_juegos{
    background: linear-gradient(131deg, var(--color-purple-dark) 23.13%, var(--color-Pink-Light) 211.53%);
    /* se realiza para que el degradado tome el color necesario y se sube para que el ioncontent se sobreponga*/
    height: 11.739%;
}

/*
*   se usa para que dentro del toolbar se cree un componente del tamaño que se va a monstrar, esto porque el toolbar es muy grande y solo se muestra un pedacito.
*/
.contenedor_titulo{
    height: 12.43%;
    padding-top: 3.199svh;
}

