.gridBotones{
    width:100%;
    height: 80%;
    top: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2%;
    row-gap: 10%;
    margin: 2%;
    background: transparent;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.gridBotones button:nth-child(n){
    height: 11vh;
    width: 11vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-purple-dark);
    border-radius: 15px;
}

.gridNavegacion{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5%;
    height: 6svh; 
    margin: 5% 0;
    overflow: visible;
}
.gridNavegacion button:nth-child(n){
    box-shadow: 0px 4px 4px 0px black;
}
.gridNavegacion :first-child{
    grid-column: 1;
}

.gridNavegacion{
    grid-column: 2;
}

.buttonRosado{
    border-radius: 15px;
    background: #D74286;
    color: white;
    width: 100%;
    height: 100%;
    font-size: 2.37svh;
    font-weight: 700;
    line-height: 40px; 
    border: none;
}
.buttonRosado span{
    color: #FFF;
    -webkit-text-fill-color: white;
}

span.spanResultado{
    font-size: 2.37svh;
    height: 2.37svh;
    display: flex;
    align-items: center;
    width: fit-content;
}

.resultado0 , .resultado1, .resultado2 {
    margin-top: 0%;
    margin-bottom: 70.9px;
    font-family: Source Sans Pro;
    font-size: 7.15rem;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    text-transform: uppercase;
    position: relative;
}

.resultado0 {
    color: var(--color-purple-dark);
}
.resultado1 {
    color: #FFB21C;
}
.resultado2 {
    color: #D74286;
}

.mensajePersonalizado{
    font-size: 3svh;
    margin: 0;
    padding: 0 5%;
    font-family: inherit;
    font-weight: 700;
}
.mensajePersonalizado span{
    font-size: 1.5rem;
}

.mensajeResultado{
    margin: 2% 10%;
    text-align: center;
    width: 80%;
    font-size: 1.5vh;
    font-weight: 400;
    color:  grey;
}