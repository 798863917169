

.home-page
{
  min-height: 50vh;
  padding:0px;
  
}

.contenido-pages, .home-page
{
  min-height: 50vh;
  position: relative;
}

.home-toolbar{
  height:60svh !important;
  flex-direction: column;
}

.margin-header-home{
  transition: margin-bottom 1.5s;
}


.datos-personales-page{
  padding-top: 11.8vh
}

.toolbarDatosPersonales{
  /* correct */
  height: 30.34svh !important;
  margin-bottom: -15.88svh;
  color: white;
  background: linear-gradient(136deg, var(--color-purple-dark) 26.38%, var(--color-Pink-Light) 144.81%) !important;
  position: relative;
}

.margin-header-juegos
{
  margin-bottom: -10vh;
}


.nombre-datosPersonales
{
  margin-top: 4vh;
  margin-left: 3%;
  margin-right: 3%;
  font-size: 2.89svh;
  font-style: normal;
  font-weight: 700;
  color: #404040;
  text-transform: uppercase;
}

.linea-datosPersonales
{
  background-color: black;
  width: 54.6%;
  height: 1px;
  margin-top: 1.5svh;
  margin-bottom: 1.5svh;
}
.email-datosPersonales{

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  color: #868189;
  margin-bottom: 6.87svh;
} 
.close{
  align-self: flex-end;
}
.modalCortinaDatosPersonales{
  justify-content: flex-end !important;
}
.modalDatosPersonales{
  width: 100% !important;
  height: auto !important;
  border-radius: 20px 20px 0px 0px !important;
  padding: 2.37svh 4.35% 0 4.35%;
}

.buttonMorado{
  border-radius: 15px;
  background: var(--color-purple-dark);
  width: 73.6%;
  height: 5svh;
  color: #FFF;
  font-size: 1.89svh;
  font-weight: 700;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-top: 3.67svh;
  margin-bottom: 6.5svh;
}
.buttonsModal{
  width: 73.6% !important;
  height: 5svh !important;
}
.title-home
{
  font-weight: 400;
  font-size: 2.13svh;
  text-transform: uppercase;
  font-family: 'Montserrat';
  margin: 0;
  max-width: 75%;
}
.nombre
{
  text-transform: capitalize;
  display: block;
  font-weight: 700;
  font-size: 4.73vh;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.buttons-datosPersonales{
  width: 67.2% !important;
  height: 5.17vh !important;
  letter-spacing: 0.8px;
}

.resultadosTest{
  margin-top: 5vh;
}
.editar-datosPersonales{
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.button-editar
{
  font-weight: 600;
  --color:#ffffff;
  font-size: 1.66vh;
  width: 10vh;
  margin: 0px !important;
  align-items: end;
  text-transform: none !important;
}
.text-empezar
{
  color: #FFF;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; 
}
.title-home, .nombre, .text-empezar
{
  font-style: normal;
  color: #FFFFFF;
}
.marco-foto
{
  height: 10vh;
  width: 10vh;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 10vh;
  min-height: 10vh;
  vertical-align: middle;
}
.marco-foto-morado{
  border: 2px solid var(--color-purple-dark) ;
  height: 18.6vh;
  width: 18.6vh;
  min-width: 18.6vh;
  min-height: 18.6vh;

}

.contenedor-neuronas-datosPersonales {
  width: 15.87vh;
  height: 6.6vh;
  border-radius: 34px;
  background: var(--color-degradado-vertical);
  padding: 2px;
  box-sizing: border-box;
  /* margin-left: 7.935vh; */
  transform:translateX(50%);
  margin-top: -6vh;
  z-index: 14;
}
.neuronas-datosPersonales {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 34px;
  background:white;
  box-sizing: border-box;
  padding-left: 5%;
}
.neuronas{
  background:var(--color-degradado-vertical);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.7vh;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  flex-grow: 1;
  text-align: center;
}
.circulo-neuronas{
  border-radius: 50%;
  height: 6.2vh;
  width:6.2vh;
  background: var(--color-degradado-vertical);
  align-items: center;
  justify-content: center;
  display: flex;
}
.neurona-icon{
  width: 70%;
}
.div-neurona-home{
  z-index: 15;
  justify-content: space-between;
  display: flex; 
  flex-direction: row;
  align-items: center;
  /* align-self: center; */
  /* margin-left: 1%; */
  width:max-content;
  /* text-align: justify; */
  /* align-items: center; */
  /* width: fit-content; */
  height: 3.5vh;
  /* border-color: #FFFFFF; */
  background-color:#FFFFFF;
  margin-top:-1.29vh;
  padding: 0.15vh 0.15vh 0.15vh 0.15vh;
  border-radius: 34vh;
  box-sizing: content-box;
}
.div-neurona{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.35vh;
  width: 3.35vh;
  padding: 0.45vh 0.5vh 0.5vh 0.45vh;
  background:var(--color-degradado-vertical);
  border-radius: 100%;
  margin: 0% 0.15vh 0% 0.25vh;
  /* margin-top: 5%; */
}
.degradee{
  /* text-align: center; */
  padding: 0 0 0 0;
  /* width:  ; */
  /* height: 2.5vh; */
  margin-left: 0.5vh;
  margin-right: 1.15vh;
  margin-block-start: 0%;
  margin-block-end: 0%;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.5vh;
  font-style: normal;
  font-weight: 800;
  /* line-height: 2.5rem; 285.714% */
  text-transform: capitalize;
  /* line-height: 120%; */
  background: linear-gradient(180deg, var(--color-purple-dark) 0%, var(--color-Pink-Light) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.foto-usuario
{
  z-index: 13;
  max-width: 100%;
  max-height: 100%;
  
}
.contenedor-foto
{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8.8vh;
  width:  8.8vh ;
  max-height: 8.8vh;
  max-width: 8.8vh;
  overflow: hidden;
  border-radius: 50%;
  background-color: #FFFFFF;
}
.contenedor-foto-datos{ 
  height: 16.6vh;
  width: 16.6vh;
  max-height: 16.6vh;
  max-width: 16.6vh;
}

.contenedor-title-home
{
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 1vh;
  width: 90%;
}
.contenedor-title-foto
{
  max-height: 12vh;
}

.contenedor-empezar-button
{
  font-family:'Montserrat';
  font-size: 1.66vh;
  align-items: center;
}

.contenedor-title-foto, .contenedor-empezar-button
{
  display: flex;
  justify-content: space-between;
  height: fit-content;
}

.card-test-inicial{
  background: #FFFFFF;
  /* linear-gradient(132deg, var(--color-Pink-Light) 0%, #45225F 99.99%, #45225F 100%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0%;
  margin-top: 2.79%;
  margin-left: 0%;
  padding-top: 1.4vh;
  padding-bottom: 0;
  /* padding: 10% 0% 10% 0%; */
  width: 100%;
  height: 21.91vh;
  text-align: center;
  /* align-items: center; */
  justify-content: start;
}
/* 
.button-test-inicial{
  --background: linear-gradient(132deg, var(--color-Pink-Light) 0%, #45225F 99.99%, #45225F 100%);
  --border-radius: 20px;
  width: 80%;
  height: 45px;
  color: #FFF;
  font-family: 'Source Sans Pro';
  font-size: 171.43%;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
} */
.text-subtitle{
  color: var(--White, #FFF);
  text-align: center;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem; 
  margin: 0;
}
.text-empecemos{
  color: #404040;
  /* leading-trim: both;
  text-edge: cap; */
  font-family:'Source Sans Pro';
  font-size: 2.37vh;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
}

.pageJuegos{
  height: 93.62% !important;

}
/**
* contenedor de las secciones de juegos (agilidad y memoria)
*/
.contenedor_juegos{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5.68svh 5.128% 1.66svh 5.128%;

  /* se ajusta al tamaño del ionContent*/
  height: 100%;
  width: 100%;

  /* el background tiene una curva morada que se encuentra ubicada totamlmente abajo*/
  background: url(/public/assets/componentes/Juegos/curva_morado.png),#FFF;
  background-repeat: no-repeat;
  background-position: left 0% bottom;
  background-size: contain;

  /*atributos que van a heredar los textos que tienen los hijos*/
  color: white;
  font-family: 'Montserrat';
  font-style: normal;
  text-transform: uppercase;
  border-radius: 15px 15px 0 0 ;
  position: relative;
}


/**
* contenedor el la pagina de juegos para la seccion de juegos de agilidad
*/

.card_juegos_agilidad{
  
  /* tienen dos background la neurona ubicada a la derecha y el fondo degradado*/
  
  background: url(/public/assets/componentes/Juegos/fondo_agilidad_mental.svg),
    linear-gradient(143deg, #FAAE83 5.5%, var(--color-Pink-Light) 98.17%);
  
  /*para que no se repita la imagen que tiene de background*/
  background-repeat: no-repeat;

  /*ubica la imagen a la derecha abajo*/
  background-position: right 0% bottom 0,0px 0px;

  /*para que se ajuste a la altura del contenedor*/
  background-size: contain;
}

/**
* contenedor el la pagina de juegos para la seccion de juegos de memoria
*/

.card_juegos_memoria{

  /* tienen dos background la neurona ubicada a la derecha y el fondo degradado*/
  
  background: url(/public/assets/componentes/Juegos/fondo_memoria.svg),
  linear-gradient(153deg, #5755CA 40.94%, #4F83DB 75.48%);;
  
  /*para que no se repita la imagen que tiene de background*/
  background-repeat: no-repeat;

  /*ubica la imagen salida del contenedor para que solo se muestre una parte del cerebro de la imagen de fondo*/
  background-position: right -10% bottom 80.8%,0px 0px;

  /*la imagen tiene un tamaño mas grande para lograr el efecto*/
  background-size: auto 175%,contain;
}

/**
* page:Juegos
* atributos en en comun de las dos cajas de tipos de juego, 
*/
.card_juegos_agilidad, .card_juegos_memoria{
  width: 100%;
  height: 21.66%;
  margin-bottom: 2.84svh;
  border-radius: 20px;
  padding: 4.28%;
  display: grid;
  grid-template-columns: 1fr;
  /*hereda el color del padre, para que no coloque el color que tiene la etiqueta (a)*/
  color:inherit;

  /*para que no coloque el texto subrayado porque es una etiqueta (a)*/
  text-decoration: none;
}
/*
* page: Juegos
* estilos para el texto "juegos de " este va en los dos tarjetas (memoria y agilidad)
*/
.text_juegoDe{
  margin: 0;
  font-size: 1.9svh;
  font-weight: 500;
  line-height: 159.375% ;
  align-self: start; 
  color: inherit;
}

.nombre_grupo_juegos{
  margin: 0;
  font-size: 3.79svh;
  font-weight: 700;
  line-height: 100%;
  align-self: end; 
  color: inherit;
}

/**
* imagen de el supercerebros nice que se coloca en la pantalla de juegos
*/
.imagen_sc_nice{
  /* se saca del flujo para ubicarla totalmente abajo y a la izquierda del contenedor*/
  position: absolute;
  left: -6%;
  bottom: 1.26%;
  height: 39%;
}

/**
* es el contenedor del globito(mensaje de "tienes un rato para jugar") en la pagina Juegos
*/
.contenedor_globito_sc{
  /* se divide el ancho en dos fracciones esto es para poder ubicar el globo de mensaje al lado del Sc*/
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  width: 100%;
}

/**
* page:Juegos
* en la pagina el Sc tiene un icono de mensajito que dice quiere jugar, este es la clase de ese mensaje
*/
.rato_para_jugar{
  /* correct */
  
  /* se coloca la forma de mensaje de fondo*/
  background:  url(/public/assets/componentes/Juegos/un_rato_para_jugar.svg);
  background-repeat: no-repeat;
  background-size: contain;

  /*se define un tamaño y el ancho es 2.32 veces el alto*/
  aspect-ratio: 2.32;
  /* height: 8.77svh; */
  width: 100%;

  /*se ubica en la  columna dos porque el contenedor es un grid de dos columnas*/
  grid-column: 2;

  /* estas son para ajustar el texto del mensaje centrado verticalmente y horizontalmente*/
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;

  font-size: 1.9svh;
  font-weight: 700;
  line-height:  150%;
  text-transform: uppercase;
  margin-top: 5.92svh;

}

.texto-normal{
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

.page-bloqueado{
  background: rgba(128, 128, 128,1);
}

.content-bloqueado{
  --background: rgb(128, 128, 128,15%);
}

.bloqueo-pagina{
  position: absolute;
  z-index: 20;
  display: flex;
  /* color: #FFFFFF; */
  top: 0%;
  background-color:grey;
  opacity:15%;
  align-items: center;
  justify-content: center;
  /* margin: 30% 30% 30% 30%; */
  /* padding: 30% 30% 30% 30%; */
  width: 100%;
  height: 100%;
}

.contenedor{
  opacity: 100%;
  background-color: grey;
  padding: 5% 5% 5% 6%;
  width:26.2vh;
  border-radius: 47.5%;
  height: 30vh;
}


.mensaje{
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  line-height: 2.5vh;
  text-transform: uppercase;
  width:85%;
  font-size:2.5vh;
  font-weight:700;
  color: var(--color-purple-dark);
}

.title-mensaje{
  position: relative;
  line-height: 40px;
  font-size:3vh;
  color: var(--color-purple-dark);
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  
  margin-top:10%;
  width:85%;
  background: linear-gradient(180deg, var(--color-purple-dark) 0%, var(--color-Pink-Light) 100%);
  background-clip: text;
  
  -webkit-text-fill-color:transparent;
}

.linea{
  height: 2px;
  width: 80%;
  text-align: center;
  background-color: var(--color-purple-dark);
  
}

.card-home{
  background: var(--color-purple-dark);
  width: 90%;
  color: #FFFFFF;
  display: grid;
  height: 17.4vh;
  margin: 0% 5% 13px 5%;
  padding: 5% 5% 2% 5%;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Montserrat';
  box-shadow: -4px 4px 8px 1px black;
  margin: 5%;
}

.dos-filas{
  grid-template-rows: repeat(2,2fr);

}

.dos-columnas{
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns:repeat(2,2fr);
  justify-items: center;
}

.bloqueo-de-pagina{
  background: red;
}

.textJuegos{
  position: relative;
  color: #FFF;
/* leading-trim: both;
text-edge: cap; */
font-family: 'Source Sans Pro';
font-size: 48px;
font-style: normal;
font-weight: 900;
line-height: 40px; /* 83.333% */
text-transform: uppercase;
z-index: 20;
}

.contenedor-logo{
  position: absolute;
  width: 30vh;
  height: 23.69vh;
  left: -13.45%;
  top:-27%;
  opacity: 60%;
}

.subtitleHome{
  /* correct */
  text-align: center;
  color: var(--color-purple-dark);
  font-size: 2.4svh;
  font-weight: 700;
  line-height: 102%;
  padding: 0 17%;
}

.subtitleHome_black{
  /* correct */
  color: var(--color-Dark-Grey);
  margin-top: 7%;
  font-weight: 600;
  font-size: 1.5vh;
}

.subtitleHome-noMargin{
  margin: 0;
}

.subtitulo-home-blanco{
  /* correct */
  position: absolute;
  height: fit-content;
  font-family: Source Sans Pro;
  font-size: 300%;
  z-index: 11;
  text-transform: uppercase;
  font-weight: 900;
}


.centerFlex{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.containerHome{
  width: 100%;
  margin-top: 3svh;
}

.containerHome-entrenamiento{
  /* correct */
  border: 2px solid var(--color-Dark-Grey);
  padding: 2% 16.7%;
  width: 70%;
  border-radius: 25px;
  font-size: 3vh;
  margin: 0;
  font-weight: 600;
  color: var(--color-Dark-Grey);
}

.home-button_rosado{
  /* correct */
  max-width: 60%;
  margin-top: 5%;
  padding: 2%;
}