.fondo-presentacion{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* z-index: 12; */
    /* right: 0; */
    left: 50%;
    transform: translateX(-50%);
    width: 110vw;
    height: 100vh;
    background: var(--color-degradado-vertical);
}

.contenedor-icono-fondo{
    position: absolute;
    opacity: 50%;
    top:-20%;
    left: -7%;
    height: 120%;
    width: 85%;
    background: transparent;
}

.title-presentacion{
    z-index: 12;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 6vh;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 10vh;
    margin-bottom: 5vh;
}

.el-grid{
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin: 0% 10% 0% 10%;
    padding: 0% 10% 5% 10%;
    width: 100%;
    height: 90%;
    grid-template-columns: repeat(2,2fr);
    color: white;
}

.label{
    width: 2vh;
    display: flex;
    align-items: center;
    color: #FFF;
    text-align: center;
    margin: 0% 10% 0% 5%;
    font-family: Montserrat;
    font-size: 6vh;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

.el-input{
    width: 90%;
    background-color: #ECECEC;
    border-radius: 20px;
    color: rgba(81, 35, 98, 0.80);
    text-align: center;
    font-family: Montserrat;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;

}

.cuenta_regresiva{
    z-index: 12;
    width: 90%;
    height: 70%;
    /* background-color: #ECECEC; */
    border-radius: 20px;
    color:white;
    text-align: center;
    font-family: Montserrat;
    font-size: 60vh;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
}

ion-button.button_presentacion::part(native){
    border-radius: 47.5px;
    font-family: Montserrat;
    font-weight: bolder;
    
    
}

.button_presentacion{
    background: linear-gradient(132 deg, #D74286 0%, var(--color-purple-dark) 99.99%, var(--color-purple-dark) 100%);
    width: 60%;
    height: 40%;
    border-radius: 20;
    font-family: Montserrat;
    font-size: 20vh;
}