.text{
  /* correct */
  margin: 2.13vh 0% 3.1vh 0%;
  width: 100%;
  padding: 0 7%;
  color: var(--White, #FFF);
  text-align: center;
  font-size: 1.8vh;
  font-style: normal;
  font-weight: 600;
  line-height: 192.3%; 
  text-transform: uppercase;
}

.boxInput{
  /* correct */
  width: 100%;
  height: fit-content;
  padding: 2vh 1vh;
  background-color: #D74286;
  border-radius: 20px;
}

.boxInput input{
  /* correct */
  background-color: white;
}

.tituloInputBox{
  /* correct */
  color: white;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; 
  text-transform: uppercase;
}
.campoObligatorio{
  /* correct */
  color: white;
  margin: 0%;
  margin-top: 5%;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; 
  text-transform: uppercase;
}

.cajaMensaje_cortesia{
  position: relative;
  background: url(../../../../../../public/assets/images/CuadroLandingCortesia.png) top / 100% 100% no-repeat;
  padding: 5% 10% 20%;
  color: white;
  aspect-ratio: 1 / 0.25;
  font-family: Montserrat;
  font-size: 2.5svh;
  font-weight: bold;
  text-align: center;
  margin-left: -5.5%;
  width: 111%;
}

.cajaMensaje_cortesia::after{
  content: '';
  position: absolute;
  background: white;
  height: 2px;
  width: 70%;
  margin: 0 15%;
  left: 0;
  bottom: 35%;
}

.campoObligatorio_cortesia{
  color: var(--color-Pink-Light);
  margin: 0;
  padding: 1svh 0;
  line-height: 100%;
  font-size: 2svh;
  width: 100%;
  height: fit-content;
}

.superOferta{
  /* correct */
  font-size: 2em;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 0;
  background:linear-gradient(180deg, var(--color-purple-dark) 0%, #D74286 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container_grid{
  /* correct */
  margin-top: 2vh;
  overflow: hidden;
  display: grid;
  grid-template-columns:0.25fr 1fr;
  height: fit-content;
  font-size: 1.66vh;
  column-gap: 8.26%;
  padding-left:7.1%;
  padding-right: 7.1%;
  align-items: center;
  gap: 2svh;
}

.container_grid_exp{
  height: 50vh;
}

.parrafo2{
  /*  correct */
  color: #404040;
  font-weight: 500;
  line-height: 153%;
  margin: 0%;
  margin-top: 0;
  font-size: 1.25em;
  height: fit-content;
}
.parrafo2Resaltado {
  /* correct */
  height: fit-content;
  color:  #404040;
  text-align: justify;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 162.5% ;
  margin: 0;
}
.columna1{
  /* correct */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.cajonTime{
  /* correct */
  /* contdor regresivo correct */
  background: linear-gradient(180deg, var(--color-purple-dark) 0%, #D74286 100%); 
  height: 100%; 
  width: 100%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--White, #FFF);
  text-align: center;
  font-family: Montserrat;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5625rem;
  text-transform: uppercase;
}

.subtitleTime{
  /* correct */
  position: relative;
  color: #D74286;
  margin: 0;
  color: var(--Pink-Light, var(--degradee2, #D74286));
  text-align: center;
  font-family: Montserrat;
  font-size: 60%;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
}

.texto_globo{
  /* correct */
  margin:0;
  top:0;
  text-align:center;
  position:absolute;
  width:100%;
  height:100%;
  padding:13% 10%;
  color:var(--color-purple-dark);
  font-weight:400;
  font-family:Montserrat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.respuestaTexto{
  /* correct */
  height: fit-content;
  padding-left: 7%;
  overflow: hidden;
  transition: height 1s;
  color: #404040;
  font-weight: 400;
  line-height: 153%;
  text-align: justify;
  margin: 0;
}

.preguntaTexto{
  /* correct */
  color: var(--color-purple-dark);
  font-weight: 700;
  line-height: 160%; 
  text-transform: uppercase;
  font-size: 1em;
  margin-top: 0;
}

.normal_text{
  color: var(--color-purple-dark);
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 169%; 
  margin: 0;
}

.div_con_desvanecimiento{
  /* correct */
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(white, transparent);;
}

.text_info{
  /* correct */
  color: #FFF;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 200%; 
}

.showInfo{
  /* correct */
  font-family: 'Montserrat';
  font-style: normal;
  padding: 0 4.87%;
}

.tituloMorado{
  /* correct */
  color:  var(--color-purple-dark);
  text-align: center;
  font-size: 2.37vh;
  font-weight: 700;
  line-height: 117.5%;
  text-transform: uppercase;
}

.parrafoGris{
  /* correct */
  color:  #404040;
  text-align: justify;
  font-size: 1em;
  font-weight: 500;
  line-height: 185.714%;
  margin: 0 0 4.14vh 0;
  padding: 0 7.1%;
}


.descuento span {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  color: inherit;
}


.buttonEstatico{
  /* correct */
  width: calc(70svh - 10%);
  height: 4.73vh;
  position: fixed;
  left: 50%;
  bottom: 10px;
  z-index: 2001;  
  transform: translateX(-50%);
  color: #FFF;
  text-align: center;
  font-size: 1em;
  font-weight: 700;
  line-height: 123.333%;
  text-transform: uppercase;
  border-radius: 15px;
  background: #D74286;
} 

.preguntasFrecuentes{
  /* correct */
  font-size: 1em;
}
.tituloPreguntasFrecuentes{
  /* correct */
  text-align:center;
  color:var(--color-purple-dark);
  margin-top:2%;
  margin-bottom:5%;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 125% ;
  text-transform: uppercase;
}
.preguntas{
  display: grid;
  grid-template-columns: 1fr 10% ;
  grid-gap: 10px; 
}
.preguntas >p:nth-child(3n) {
  grid-column: 1 ;
}
.preguntas >p:nth-child(3n+4) {
  grid-column: 1 ;
}
.contenedorPrecioDescuento{
  /* correct */
  display:flex;
  align-items:center;
  flex-direction:column;
  position: relative;
  margin-bottom: 5.68vh;
}

.descuento{
  /* correct */
  background-image:url('../../../../../../public/assets/icon/icon_descuento.svg');
  height:23.69vh;
  width:23.69vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  color: white;
  z-index: 2;
  position: absolute;
  left: 0;
  bottom: -5.68vh;
}


.precioCurso{
  /* correct */
  border:2px solid var(--color-purple-dark);
  border-radius:15px;
  align-self: flex-end;
  display:flex;
  align-items:center;
  justify-content: end;
  height:11vh;
  color:#D74286;
  width: 79.5%;
  margin-right: 3.13%;
  padding-right: 8.24%;
  
  font-size: 3em;
  font-weight: 700;
  line-height: 56.818%;
  text-transform: uppercase;
}
.precioCurso p span{
  /* correct */
  font-size: .5em;
  justify-content: end;
}
.contenedorFoto{
  /* correct */  
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 111%;
  margin: 0 -5.5%;
  position: relative;
  font-style: normal;
  color: #ffffff;
  border-radius: 25px 25px 40% 40%;
  background: linear-gradient(167deg, var(--color-purple-dark) 7.98%, #D74286 79.34%);
}
.tituloSobreNosotros{
  /* correct */
  margin-bottom: 7svh;
  color: #FFF;
  text-align: center;
  font-size: 2em;
  font-weight: 700;
  line-height: 82.813%;
  text-transform: capitalize;
  margin-top: 10%;
}

.juanDavid{
  /* correct */
  height: auto;
  width: 100%;
}

.textoSobreNosotros{
  /* correct */
  width: 100%;
  border-radius: 0px 0px 50px 50px;
  border: 1px solid var(--color-purple-dark) ;
  padding: 7.5svh 11% 8% 11% ;
  color: var(--color-purple-dark);
  text-align: justify;
  font-size: 1em;
  font-weight: 400;
  line-height: 162.5%;
  margin-top: -5.9svh;
}
.textoSobreNosotros span{
  font-weight: 700;
}

.unmuteButton{
  position: absolute;
  top: 1svh;
  left: 1svh;
  width: 5svh;
  aspect-ratio: 1 / 1;
  background: url(../../../../../../public/assets/icon/soundIcon.png) center / 70% no-repeat,var(--color-Pink-Light);
  border-radius: 100%;
  z-index: 1;
}

.video_cortesia{
  max-height: 50svh;
}

.modalMensaje{
  background: white;
  height: fit-content;
  width: 80%;
  display: flex;
  justify-content: center;
  padding: 5%;
  align-self: center;
  border-radius: 25px;
  border: 3px solid var(--color-purple-dark);
}

.fondoDegradado_sombra{    
  width: 100%;
  position: absolute;
  top: 0;
  padding: 10%;
}

.fondoDegradado_sombra .label{
  color: white;
  font-size: 2.76543svh;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

.fotosLibro{
  position: relative; 
  width: 100%;
  aspect-ratio: 1/4;  
  background: url(../../../../../../public/assets/images/landing_libro_mancha.png),transparent;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding-bottom: 5em;
}

.fotoLibro1{
  position: absolute;
  width: 100%;
  top: 3em;
  left: 0;
}
.fotoLibro2{
  position: absolute;
  width: 60%;
  top: 0;
  transform: translateY(111%);
  right: 0;
}

/* .fotosLibro::after{
  content: "";
  position: absolute;
  height: 60em;
  background: url(../../../../public/assets/images/landing_libro1.png),transparent;
  background-repeat: no-repeat;
  background-size: auto 100%;
  aspect-ratio: 1 / 1;
  top: 2em;
  left: 0;
} */

/* .fotosLibro::before{
  content: "";
  position: absolute;
  height: 53%;
  aspect-ratio: 1 / 1;
  background: url(../../../../public/assets/images/landing_libro2.png),transparent;
  background-repeat: no-repeat;
  background-size: auto 100%;
  top: 56%;
  right: -27%;
} */

.libroGrid{
  display: grid;
  grid-template-columns: 7svh 1fr;
  gap: 3svh;
  justify-items: start;
  align-items: center;
}

.IncludeLibro p{
  margin: 0;
  color: var(--color-Dark-Grey);
  font-size: 2.3svh;
  font-weight: 700;
  text-transform: uppercase;
}

.imglibro2{
  width: 100%;
  position: relative;
  margin: 0;
}
.imglibro2 img{
  width: 100%;
}
.imglibro2 span{
  font-weight: 800;
  font-size: inherit;
  color: inherit;
}
.imglibro2 p{
  font-weight: 500;
  position: absolute;
  color: white;
  font-size: 4svh;
  bottom: 2%;
  left: 0;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}

.precioMorado{
  font-size: 4svh;
  line-height: 150%;
  background-color: var(--color-purple-dark);
  width: 100%;
  height: fit-content;
  text-align: center;
  color: white;
  font-weight: 700;
  z-index: 1;
}

@media only screen and (max-width:1023px) and (min-width:768px){
  .page{
    min-width: 100%;
  }
}
@media only screen and (max-width:690px){
  .precioCurso{
    font-size: 5svw;
    padding-left: 25%;
  }
  .precioCurso p{
    width: -webkit-fill-available;
    text-align: end;
    margin: 0 !important;
  }
}