.button,
.specialButton {
    border-radius: 15px;
}

.button {
    font-size: inherit;
    font-weight: 600;
    color: #404040;
    background-color: #D9D9D9;
    border-radius: 0.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 4px rgb(0, 0, 0, 0.25);
}
.button_test {
    border-radius: 15px;

    color: black;
    background-color: #D9D9D9;
    border-radius: 20px;
    box-shadow: 0 4px 4px rgb(0, 0, 0, 0.25);

}

.specialButtonOk {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-size: 1.5rem;

    color: rgb(255, 255, 255);
    background:#D74286;
    box-shadow: 0 4px 4px rgb(0, 0, 0, 0.25);
}
.specialButtonErase {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-size: 1.5rem;

    color: rgb(255, 255, 255);
    background:var(--color-purple-dark);
    box-shadow: 0 4px 4px rgb(0, 0, 0, 0.25);
}
