/* Fondo de cobertura para modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

#page {
    height: 100vh;
    /* Ocupa la altura total de la pantalla */
    overflow-y: auto;
    /* Permite el desplazamiento vertical */
}

/* Estilos del contenido del modal */
.contentModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 85vw;
    max-width: 400px;
    padding: 2em 1em;
    box-shadow: 2px 2px 4px black;
    border-radius: 1em;
    z-index: 1000;
    overflow-y: auto;
    text-align: center;
    animation: deslizarArriba 0.5s ease-in-out;
}

.closeButton {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    background: none;
    border: none;
    color: #333;
    font-size: 1.5em;
    cursor: pointer;
}

/* Botón dentro del modal */
.buttonModal {
    margin-top: 1.5em;
    width: 80%;
    max-width: 250px;
    font-size: 1em;
    padding: 0.6em 1em;
    border-radius: 0.5em;
    background-color: var(--color-Pink-Light);
    color: white;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: block;
    margin: 1.5em auto 0 auto;
    /* Centrado y espaciado */
}

/* Imagen de fondo del modal */
.imagenSCVolando {
    position: relative;
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
    padding-top: 2em;
    padding-bottom: 1em;
}


.imageContainer {
    width: 100%;
    max-width: 200px;
    margin: 0 auto 1em auto;
    text-align: center;
}

.imageContainer img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

/* Animación para el modal */
@keyframes deslizarArriba {
    0% {
        transform: translate(-50%, 100vh);
    }

    100% {
        transform: translate(-50%, -50%);
    }
}

/* Vista para pantallas grandes */
@media screen and (min-width: 1024px) {
    .contentModal {
        max-width: 35vw;
    }

    .imagenSCVolando::before {
        width: 25%;
        /* Ajustado para pantallas grandes */
    }
}

/* Vista para tablets */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .contentModal {
        max-width: 55vw;
    }

    .imagenSCVolando::before {
        width: 35vw;
        /* Ajustado para tablets */
    }
}

/* Otros estilos */
.activity_box {
    position: relative;
    border-radius: 1.5em;
    box-shadow: 0 6px 7px rgba(0, 0, 0, 0.25);
}

.activity_bk_white {
    width: 5em;
    height: 5em;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activity_candado {
    width: 5em;
    height: 5em;
    background-image: url(../../../public/assets/icon/candado.png);
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--color-Light-Grey);
    border-radius: 50%;
}

.coneccionActivity {
    width: 6%;
    height: 5vh;
    background-color: var(--color-Light-Grey);
}

.ButtonFinalizar_activity {
    background-color: white;
    border-radius: 0.5em;
    box-shadow: 0 6px 7px rgba(0, 0, 0, 0.25);
    border: none;
    padding: 1em 2em;
    font-size: 1.2em;
    color: inherit;
    font-weight: bold;
    cursor: pointer;
}

.fuente_activity {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.25);
}

.textActivity {
    font-size: 1em;
    font-weight: bold;
    position: absolute;
    z-index: 2;
    color: white;
    max-width: 60%;
    overflow: hidden;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    padding: 3%;
    text-align: center;
    text-transform: uppercase;
}

.progressBar {
    border-radius: 1em;
    background-color: var(--color-Light-Grey);
    width: 100%;
    height: 3vh;
    display: flex;
}

.contentProgressBar {
    border-radius: 1em;
    background: linear-gradient(90deg, var(--color-purple-dark), var(--color-Pink-Light));
    height: 100%;
    width: 0%;
    transition: width 1s ease-in;
}