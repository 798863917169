.buttonEmpezar{
    background: #D74286;
    border-radius: 15px;
    border: none;
}

.buttonVolver{
    background: transparent;
    border: #D74286 solid 2px;
    border-radius: 15px;
    width: 100%;
    height: 17.14%;
    color: #D74286;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 2.36svh;
    text-transform: uppercase;

}

.title{
    line-height: 120%;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    flex: 1;
}

.bannerPrograma{
    background: linear-gradient(147deg, #D74286 -31.57%, var(--color-purple-dark) 118.91%);
}

.contenedorPrograma{
    background: #D74286;
}

.juegosIcono{
    width: 14.25svh;
    height: 14.25svh;
}

.programaIcono{
    /* correct */
    rotate: 10deg;
    width: 18.25svh;
    height: 18.25svh;
}

.contenedorPrograma::after{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    background-color: transparent;
    border: #FFF solid 7px;
    width: 19svh;
    height: 19svh;
}

.backButton{
    position: absolute;
    height: 2.84svh;
    left: 2.96svh;
}