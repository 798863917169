.button_entidades{
    height: 5%;
    background-color: #D74286;
    border-radius: 5px;
    color: white;
    font-size: 2svh;
    text-transform: uppercase;
    padding: 5px;
}
.card_entidad{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    border-radius: 4px;
    height: 5%;
    width: 85%;
    margin-bottom: 10px;
}

.modal_overlay{
    position: fixed;
    top: 0%;
    left: 0;
    width: 100%;
    height: 95%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.content_modal{
    padding: 5%;
    color: #868189;
    
}