.page{
    background-color: white;
    width: 100%;
    height: calc(100svh - 0svh);
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

.header{
    margin-bottom: -4.42% !important;
    height: 30svh;
}

.toolbar{
    background: var(--color-degradado-vertical);
    font-family: 'Montagu Slab';
    padding: 5% !important;
    height: 100%;
}

.toolbar div{
    display: flex; 
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.toolbar div p{
    margin-bottom: 29vh;
    margin: 0;
    font-size: 2.84svh;
    font-weight: 700;
    line-height: 125%;
    text-transform: uppercase;
    height: -moz-fit-content;
    height: fit-content;
    font-family: 'Montserrat';
}

.content{
    color: grey;
    padding: 5%;
    background-color: white;
    border-radius: 20px;
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
}

.buttonOk{
    width: 35%;
    background-color: #D74286;
    border-radius: 20px;
    height: 6svh;
    text-transform: uppercase;
    font-weight: 700;
    font-size: large;
    color: inherit;
}

.inputPersonalizado2{
    width: 80%;
    height: 2vh;
    display: flex;
    text-align: center;
    border-radius: 0.9375rem;
    border: 0;
    border: 2px solid #D74286;  
    height: auto;
    background-color: white;
}

.selector{
    display: flex;
    flex-direction: row;
    color: inherit;
    border-radius: 20px;
    overflow: hidden;
    height: 100%;
    z-index: 10;
}

.selector:focus-within{
    border: 1px solid black;
}

.selector div.cortina{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: grey;
    opacity: 0.3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.selector input.selectorInput{
    background: url(../../../public/assets/icon/flechaAbajoSelector.svg) center left/ 30px 30px, white;
    background-repeat: no-repeat;
    border-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: auto;
    padding-left: 20%;
    color: inherit;
    font-size: large;
    text-align: center;
}

.selector div.box{
    position: fixed;
    width: 70%;
    height: fit-content;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2001;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: grey solid 1px;
    border-radius: 20px;
    min-width: 39svh;
    min-height: 52svh;
}

.selector div.line{
    width: 100%;
    height: 2px;
    background-color: grey;
    margin: 20% 0 2% 0;
}

.selector div.top{
    border: none !important;
    flex: 1;
    max-height: 35vh;
    min-height: 35px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.selector div.top button{
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 48px;
    color: grey;
    justify-content: start;
    align-items: center;
    border: grey 1px solid;
    border-radius: 10px;
    margin: 2% 0;
}

.selector div.top button div{
    left: 26px;
    top: 0;
    border-radius: 50%;
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-style: solid;
    border-color: var(--ion-color-step-550, #737373);
}
.selector div.top button div div{
    left: 3px;
    top: 3px;
    border-radius: 50%;
    position: absolute;
    width: 10px;
    height: 10px;
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
    -webkit-transition: -webkit-transform 280ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 280ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 280ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 280ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: var(--color-Pink-Light);
}

.selector div.top button div div.seleccionado{
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}


.selector div.top button p{
    flex: 1 1;
    height: fit-content;
    text-align: start;
    align-self: center;
    padding: 6.5% 40px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
}


.selector div.line2{
    width: 100%;
    height: 2px;
    background-color: grey;
    margin: 2% 0 1% 0;
}

.selector div.bot{
    width: 100%;
    height: 45px;
    display: grid;
    grid-template-columns: 1fr 1fr 40% 20%;
    padding: 8px;
    gap: 5%;
}

.selector div.bot :first-child{
    grid-column: 3;
    border: none;
    border-radius: 20px;
    color: var(--color-purple-dark);
    border: var(--color-purple-dark) solid 2px;
    background: transparent;
}
.selector div.bot button:last-child{
    background-color: #D74286;
    border: none;
    color: white;
    border-radius: 20px;
}

.selector div.bot :nth-child(n){
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
}

.svg_loader{
    display:flex;
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 2001;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255,0.9);
}
.loader_svg{
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    fill: none;
    stroke-width: 5px;
    stroke-linecap: round;
    stroke: #D74286;
}
.loader_svg.bg{
    stroke-width: 8px;
    stroke: rgb(207, 205, 245);
}
.animate{
    stroke-dasharray: 242.6;
    animation: fill-animation 1s cubic-bezier(1,1,1,1) 0s infinite;
  }
  
  @keyframes fill-animation{
    0%{
      stroke-dasharray: 40 242.6;
      stroke-dashoffset: 0;
    }
    50%{
      stroke-dasharray: 141.3;
      stroke-dashoffset: 141.3;
    }
    100%{
      stroke-dasharray: 40 242.6;
      stroke-dashoffset: 282.6;
    }
  }