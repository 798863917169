.letraNegra{
    color: black !important;
}

.calculatorLetra{
    color: var(--color-Medium-Grey)!important;
    font-size: 18svh;
    font-weight: 400;
    line-height: 100%;
}

.calculator {
    /* padding-bottom: 1.5rem; */
    padding-left: 5%;
    padding-right: 5%;
    position: absolute;
    /* bottom: 11.2%; */
    left: 0;
    right: 0;
    margin: auto;
}

@media (min-aspect-ratio: 2/3) {
    .calculator{
        padding: 0% 15%;
    }
}
@media (min-aspect-ratio: 1/2) {
    .calculator{
        padding: 0% 10%;
    }
}

.calculadoraSumas1{
    max-height: 50svh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4.61%;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    overflow: hidden;
    font-size: 5vh;
    flex: 1;
    overflow: visible;
}


.progreso{
    margin-top: -20px;
    position: absolute;
}
.sin_input {
    height: 15vh;
}

.correcta {
    color:#D74286;
    margin: 0% 0% 0% 0%;
    height: 5vh;
    font-size: 5vh;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.09375rem;
    text-transform: uppercase;
}

.incorrecta {
    color: var(--color-purple-dark);
    margin: 0% 0% 0% 0%;
    height: 5vh;
    font-size: 5vh;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.09375rem;
    text-transform: uppercase;
}

.textoRespuesta{
    margin: 10% 0% 0% 0%;
    color: black;
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.09375rem; /* 167.5% */
    text-transform: uppercase;
}

.icon {
    font-size: 96px;
}

.playIcon {
    font-size: 196px;
}

.btnPlay {
    height: 100%;
}

.numero_principal {
    font-size: 15rem;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.numero_principal_respuesta {
    /* correct */
    position: relative;
    font-size: 7rem;
    text-align: center;
    color: black;
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    margin: 10%;
    width: 100%;
    row-gap: 5%;
}



.contenido_principal {
    font-size: 10rem;
    text-align: center;
}

.contenido_segundario {
    text-align: center;
    margin: auto;
    width: 100%;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    padding: 1em 3em 1em 3em;
    width: 100%;
    height: 6vh;
}

.resultado_mensaje {
    font-size: 2rem;
}    

.home_button {
    height:2em;
    width:5em;
    font-size:2em;
}

.resultado {
    /* correct */
    color: gray;
    font-size: 25svh;
    font-weight: 400;
    text-align: center;
}

.contenedor_nivel{
    position: relative;
    padding: 0% 0% 0% 0%;
    margin: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-Dark-Grey);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}

.contenedor_barra{
    width: 80%;
    display: grid;
    height: 2vh;
    margin:  1vh 2vh 2vh 2vh;
}

.contadorPartida{
    color: white;
    background-color: #D74286;
    padding: 0.5vh 1vh;
    border-radius: 1vh;
    font-variant-numeric: tabular-nums;
    margin-top: 1vh;
}

.bordeBarra{
    border: solid 2px #D74286;
}

.fondoBarra{
    background-color: #D74286;
    height: 100%;
    transition: width 1s ;
}

.tuRespuesta{
    color:#868189;
    text-align: center;
    font-size: 1.89vh;
    font-style: normal;
    font-weight: 700;
    line-height: 209.375%;
    text-transform: uppercase;
    margin: 0;
}

.subMensaje{
    color: var(--color-Dark-Grey);
    padding: 0 3vw;
    font-weight: 700;
}
.subMensaje span{
    font-size: inherit;
    color: var(--color-Pink-Light);
    font-weight: 800;
}

.mensajeCorrecto{
    color: var(--color-Pink-Light);
}
