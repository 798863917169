.navBar{
    width: 100%;
    height: 8.151%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: linear-gradient(180deg, #AD387A 0%, #592664 100%);
    border-radius: 15px 15px 0px 0px;
    padding: 0 3.85%;
    font-family: 'Montserrat';
    z-index: 111;
    position: fixed;
    left: 0;
    bottom: 0;
    
    
}

.nav_item{
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.3svh;
    text-decoration: none;
    z-index: 13;
    position: relative;
}
.nav_item:nth-child(3){
    padding-top: 1svh;
}

.nav_item img{
    height: 51.9%;
    width: auto;
    aspect-ratio: 1;
    transition: 0.5s;
}

.nav_item:nth-child(3) img{
    height: 69.2%;
}

.nav_item p{
    color: #FFF;
    text-align: center;
    font-size: 1svh;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 15.66%;
    width: 100%;
    
}

.nav_item.active img{
    transform: translateY(-20.69%);

}
.item_seleccionado{
    color: #FFE500 !important;
    text-shadow: 0px 0px 4px rgba(243, 245, 226, 0.50);
}

.ellipse{
    position: absolute;
    left: 2.82%;
    top: -4.36%;
    color: white;
    width: 20%;
    height: 71.22%;
    transition: 0.5s;
    z-index: 12;
    transform: translateX(0); 
}

.nav_item:nth-child(1).active ~ .ellipse{
    transform: translateX(calc(100% * 0));
} 

.nav_item:nth-child(2).active ~ .ellipse{
    transform: translateX(calc(93% * 1));
} 

.nav_item:nth-child(3).active ~ .ellipse{
    transform: translateX(calc(93%  * 2));
} 

.nav_item:nth-child(4).active ~ .ellipse{
    transform: translateX(calc(92.5% * 3));
} 

.nav_item:nth-child(5).active ~ .ellipse{
    transform: translateX(calc(92.5%  * 4));
} 