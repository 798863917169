.contain_integrantes{
    height: fit-content;
    min-height: 50px;
    max-height: 50svh;
    color: var(--color-Dark-Grey);
    font-size: 20px;
    width: 100%;
    margin: 2%;
}

.contain_integrantes :nth-child(n){
    font-size: inherit;
    color: inherit;
    text-align: center;
}

button.block{
    background-color: var(--color-Medium-Grey) !important;
    color: var(--color-Light-Grey) !important;
}

.contain_invitaciones{
    height: fit-content;
    min-height: 50px;
    max-height: 50svh;
    color: var(--color-Dark-Grey);
    font-size: 20px;
    width: 100%;
    margin: 2%;
    display: grid;
    grid-template-columns: 1fr 20%;
}