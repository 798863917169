.toolbarVideo {
    height: 32.76%;
    background: url(/public/assets/images/logo_sc_fondo.svg),
        linear-gradient(167deg, var(--color-purple-dark) 7.98%, #D74286 79.34%);
    background-repeat: no-repeat;
    background-position: 0% -13%, 0 0;
    background-size: cover, contain;
    padding: 4.74svh 6.41% 0 6.41%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    font-family: 'Montserrat';
    justify-content: flex-start;
}

#el-body {
    height: 100vh;
    overflow-y: auto;
    /* Permitir desplazamiento vertical */
}

.contenedorVideo {
    max-height: 60vh;
    /* Limitar la altura máxima del contenedor del video */
    overflow: hidden;
    display: flex;
    justify-content: center;
    background: #D9D9D9;
    border-radius: 23px;
}


.iconoBack {
    align-self: self-start;

}

.sesionTitulo {
    text-align: center;
    font-size: 2.37svh;
    font-weight: 500;
    line-height: 200%;
    text-transform: uppercase;
    margin: 0;
    color: white;
}

.tituloActividad {
    text-align: center;
    font-size: 1.3em;
    font-weight: 700;
    line-height: 166.667%;
    text-transform: uppercase;
    margin: 4% 0 0 0;
    color: white;
}

.contenedorDificultad {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2.13svh;
    font-family: 'Montserrat';
    font-style: normal;
    position: relative;
}

.contenedorEstrellas {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 11.26%;
    gap: 1.28%;
}

.imagen_transicion {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transition: transform 1s ease-in-out;
    flex: 1;
    position: relative;

}

.texto_dificultad {
    color: #404040;
    text-align: center;
    font-size: 1.42svh;
    font-weight: 400;
    line-height: 208.333%;
    text-transform: uppercase;
}


.dificultad {
    margin: 0;
    text-transform: uppercase;
}

.imagenDificultad {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 0;
}

.imagen_transicion.entrada {
    transform: translateX(0%);
}

.imagen_transicion.salida {
    transform: translateX(-100%);
    z-index: -1;
}

.buttonListo {
    background: #D74286;
    border-radius: 15px;
    height: 20.5%;
    width: auto;
    aspect-ratio: 2.62;
    color: #FFF;
    text-align: center;
    font-size: 1.9svh;
    font-weight: 600;
    line-height: 103.125%;
    text-transform: capitalize;
    position: absolute;
    left: 55%;
    bottom: 20%;
    z-index: 1000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}