.headerRankingPage{
    height: 32svh;
    margin-bottom: 24vh;
}
.containerRanking{
    position: relative;
    height: 100%;
    width: 100%;
    padding: 2%;
    max-height: 80vh;
    overflow-y: scroll;
}

.topTres{
    background: var(--color-degradado-vertical);
    height: 31.4vh ;
    width: 100%;
    border-radius: 24px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 1.5vh;
    display: grid;
    grid-template-columns: 1fr 1fr;   
}
.topTres :first-child{
    grid-column: span 2;
}

.paddingRanking{
    padding-left: 4.6% ;
    padding-right: 4.6%;
    padding-top: 1.4vh;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}

.numeroUno, .numeroDos, .numeroTres{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--light-grey, #D9D9D9);
    text-transform: uppercase;
    padding: 0%;
}
.numeroUno{

}
.numeroDos{
    transform: translateY(-20%);
}
.numeroTres{
    /* transform: translateY(10%); */
}
.topRank, .topNombre{
    color: white;
    font-style: normal;
    line-height: normal;
}
.topRank{
    font-weight: 900;
}
.topNombre{
    font-weight: 800;
}
.topRank1{
    font-size: 7.6vh;
}
.topRank2{
    font-size: 5.7vh;
}
.topRank3{
    font-size: 4.8vh;
}
.topNombre1{
    font-size: 1.9vh;
    
}
.topNombre2{
    font-size: 1.6vh;
}
.topNombre3{
    font-size: 1.5vh;
}
.titulos, .filaRanking, .rankNumeral{
    font-style: normal;
    line-height: normal;
}
.titulos{
    width: 100%;
    text-align: center;
    font-size: 12px;
    margin-bottom: 1.6vh;
    display: grid;
    grid-template-columns: 20% 1fr 25%;
    justify-items: center;
    align-items: center;
    font-weight: 600;
    line-height: 130%;
    color: var(--color-Dark-Grey);
}
.titleConResultado{
    grid-template-columns: 11% 1fr 20% 22% !important;
}

.filaRanking{
    width: 100%;
    height: 4vh;
    border-radius: 6px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 1.32vh;
    font-weight: 400;
    display: grid;
    grid-template-columns: 20% 1fr 25%;
    justify-content: center;
    position: relative;
    margin-bottom: 1.5vh;
    justify-items: center;
    color: var(--color-Dark-Grey);
    align-items: center;
}

.filaRanking_rosado{
    position: relative;
    background: var(--color-Pink-Light);
    color: white;
    margin-bottom: 1.5vh;
    height: 6svh;
}

.filaRanking_morado{
    background: var(--color-purple-dark);
    color: white;
    height: 6svh;
    padding: 2svh 0;
    align-items: normal;
}

.filaRankingConResultado{
    grid-template-columns: 11% 1fr 20% 22% !important;
}
.rankNumeral{
    font-weight: 600;
}

.rank1{
    position: relative;
    top: 0.5vh;
    padding: 0.5vh !important;
    color: black;
    display: flex;
    justify-content: center;
    z-index: 1;
    height: 5vh;
    width: 3vh;
    font-family: Montserrat;
}
.rank1::before{
    border-radius: 100%;
    background: white;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 3vh;
    height: 3vh;
    z-index: -1;
    
}
.rank1::after{
    content: "";
    background: url(../../public/assets/icon/rankingOne.svg) bottom / contain;
    background-repeat: no-repeat;
    z-index: 2;
    position: absolute;
    top: 30%;
    left: 0;
    width: 3vh;
    height: 3vh;
}
.tableCol{
    width: 100%;
    color: inherit;
    height: fit-content;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
}
.rank2{
    position: relative;
    height: 100%;
    margin-left: 10px;
    padding: 0% !important;
    width: 3vh;
    border-radius: 100%;
    background: url(../../public/assets/icon/rankingTwoThree.svg) center;
    background-repeat: no-repeat;
    background-size: contain;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
}
.tresPuntos{
    color: var(--color-purple-dark);
    text-align: center;
    font-family: 'Montserrat';
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 167.5% ;
    text-transform: capitalize;
    margin-top: 2.36vh;
    margin-bottom: 2.2vh;
}

.firstUser{
    overflow-y:visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(calc(1.1 / var(--ion-grid-columns, 12)) * 100%);
    margin-left:'10px';
}

/* Modulo de RankingPage.tsx */
.modal{
    height: fit-content !important;
    overflow: hidden;
    padding: 0 !important;
}

.modal .top{
    height: fit-content;
    border-radius: 16px;
    width: 100%;
    background: var(--color-degradado-vertical);
    text-align: center;
    color: var(--color-Light-Grey);
    font-weight: 600;
    font-size: 12px;
    padding: 10%;
}

.modal .top :first-child{
    color: white;
    font-size: 20px;
    text-transform: uppercase;
}

.modal .bot{
    width: 100%;
    gap: 5px;
    grid-template-columns: 1fr;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 5%;
}

.modal .bot label{
    width: 100%;
    border-radius: 15px;
    background-color: var(--color-Pink-Light);
    text-align: center;
    text-transform: uppercase;
    color: white;
}