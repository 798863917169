.title{
    text-align: center;
}

.title .entrenamiento{
    text-align: center;
    font-family: Montserrat;
    font-size: 130%;
    font-style: normal;
    font-weight: 1000;
    line-height: 110%; 
    text-transform: uppercase;

    background: linear-gradient(165deg, var(--color-purple-dark) -52.34%, var(--color-Pink-Light) 152.4%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.title .pago{
    color: var(--color-purple-dark);
    text-align: center;
    font-family: Montserrat;
    font-size: 100%;
    font-style: normal;
    font-weight: 700;
    line-height: 177%; 
    text-transform: uppercase;   
}

.factura{
    height: fit-content;
    background-color: var(--color-Pink-Light);
    margin: 3%;
    border-radius: 40px;
    text-align: center;
}

.factura p{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 150%;
    font-style: normal;
    font-weight: 1000;
    text-transform: uppercase;
    margin-top: 5%;
    margin-bottom: 3%;
}

.linea{
    display: grid;
    grid-template-columns: repeat(16,1fr);
    justify-items: center;
    column-gap: 2%;
    margin: 0 5%;
    height: 2px;
}

.linea div{
    background-color: white;
    height: 100%;
    width: 100%;
}

.backMorado{
    background-color: var(--color-purple-dark);
    margin: 0 !important;
    padding: 2% 7% !important;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5%;
    justify-items: flex-start;
    margin: 0 5%;
    padding: 2%;
}

.grid p{
    color: #FFF;
    font-family: Montserrat;
    font-size: 120%;
    font-style: normal;
    font-weight: 500;
    line-height: 35.5px; /* 236.667% */
    text-transform: uppercase;
}

.grid p.valor{
    font-weight: 1000;
}

.boxInput{
    position: relative;
    height: fit-content;
    padding: 2vh 1vh;
    border-radius: 20px;
    margin: 0 5%;
}

.boxInput p.campo{
    text-transform: capitalize;
    width: 100%;
    text-align: end;
    color: var(--Pink-Light);
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    margin: 0;
}

.boxInput div.telefono{
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    column-gap: 2%;
}

.boxInput div.divButton{
    display: flex;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
}

.boxInput div.divButton button{
    width: 50%;
    font-weight: 700;
    text-transform: uppercase;
}

.boxInput div.divButton button{
    background-color: var(--color-Pink-Light);
    height: fit-content;
    margin: 0;
    padding: 1%;
    border-radius: 15px;
}

.boxInput div.mediosdePago{
    display: flex;
    flex-direction: row;
    gap: 1%;
    color: black;
    max-height: 5vh;
    max-width: 100%;
    overflow: hidden;
    margin-top: 2%;
}

.boxInput div.mediosdePago img{
    max-width: 16.3%;
}
  
.tituloInputBox{
    color: var(--White, #FFF);
    text-align: center;
    font-family: 'Montserrat';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5625rem; /* 125% */
    text-transform: uppercase;
}
.campoObligatorio{
    color: var(--color-purple-dark);
    margin: 0%;
    margin-top: 2%;
    font-family: Montserrat;
    font-size: 1.5vh;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 178.571% */
    text-transform: uppercase;
}

.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
}

.modal .content{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    width: 70%;
    text-align: center;
    border-radius: 20px;
    border: var(--color-purple-dark) solid 2px;
    background-color: white;
    position: fixed;
    padding: 5%;
}

.modal .content .title_mensaje{
  position: relative;
  line-height: 40px;
  font-size:3vh;
  color: var(--color-purple-dark);
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  
  margin-top:10%;
  width:85%;
  font-weight:700;
  font-family:'Source San Pro';
  background: linear-gradient(180deg, #45225F 0%, var(--color-Pink-Light) 100%);
  background-clip: text;
  text-align: center;
  
  -webkit-text-fill-color:transparent;
}

.modal .content .linea{
    height: 2px;
    width: 100%;
    margin: 5% 0;
    text-align: center;
    background-color: var(--color-purple-dark);
}

.modal .content .mensaje{
  text-align: center;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5vh;
  text-transform: uppercase;
  
  margin-top:10%;
  width:85%;
  font-size:2.5vh;
  font-weight:700;
  color: var(--color-purple-dark);
  font-family:'Source San Pro';
  text-align: center;
  
}

.modal .content .buttonTestInicial{
    background: linear-gradient(132deg, var(--color-Pink-Light) 0%, #45225F 99.99%, #45225F 100%);
    border-radius: 20px;
    width: 80%;
    height: 45px;
    color: #FFF;
    font-family: 'Source Sans Pro';
    font-size: 171.43%;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
    margin: 2% 0;
}

.volver{
    border: var(--color-Pink-Light) solid 1px;
    background-color: white;
    width: 100%;
    border-radius: 20px;
    color: var(--color-Pink-Light);
    padding: 2%;
    font-weight: 1000;
    margin-bottom: 5%;

}

.selector{
    /* correct */
    color : var(--color-Dark-Grey);
    background-color: var(--color-Light-Grey);
    border-radius: 15px;
}