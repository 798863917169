.marginHeaderJuegos
{
  margin-bottom: -16.15% !important;
  height: 43.6%;
}
.marginHeaderEntrenamiento
{
  margin-bottom: -4.42% !important;
  height: 30%;
}

/**
* estilos que son usados por todos los tolbar de los juegos
*/
.toolbarMemoriaNombres, .toolbarMemoriaPalabras, .toolbarSCDice,
.toolbarMemoriaNumeros, .toolbarCalculoMatematico, .toolbarParejas,
.toolbarCalculoFecha, .toolbarNeuralTrainer
{
  height: 47.48% ;
  
  /* background negativo para que se suba el siguiente contenedor sobre este*/
  margin-bottom: -5.568svh;
  justify-content: center;
  padding: 8.88svh 8.97% 0 8.97% ;
  text-align: center;
}

/**
* estilos para el toolbar de el juego de memoria de palabras 
*/
.toolbarMemoriaPalabras
{
  /*tiene dos background las neuronas y el degradado del fondo*/

  background:url('../../public/assets/componentes/Juegos/fondo_neuronas_azul_verde.svg'), 
    linear-gradient(153deg, #4A86DF 26.7%, #77B15C 88.97%);
  background-repeat:repeat-x;

  background-position: left 0% bottom 4.86svh,0px 0px;
}

.toolbarMemoriaNombres
{
  /*tiene dos background las neuronas y el degradado del fondo*/

  background:url('../../public/assets/componentes/Juegos/fondo_neuronas_azul_verde.svg'), 
    linear-gradient(165deg, #77B15C 8.94%, #4D84DD 90.56%);
  background-repeat:repeat-x;
  background-position: left 0% bottom 4.86svh,0px 0px;
}

.toolbarSCDice
{
  /*tiene dos background las neuronas y el degradado del fondo*/

  background:url('../../public/assets/componentes/Juegos/fondo_neuronas_morado_rosado.svg'), 
  linear-gradient(326deg, #D84585 -31.52%, #5755CA 87.31%);
  background-repeat:repeat-x;

  background-position: left 0% bottom 4.86svh,0px 0px;
}

.toolbarMemoriaNumeros
{
  /*el degradado del fondo*/

  background:linear-gradient(146deg, #5755CA 30.3%, #4A86DF 86.08%);

}
/**
* estilos para el toolbar de el juego de calculo Matematico
*/
.toolbarCalculoMatematico
{
  /*tiene dos background las neuronas y el degradado del fondo*/

  background:url('../../public/assets/componentes/Juegos/fondo_neuronas_naranja_rosado.svg'), 
    linear-gradient(152deg, #FF927A 10.65%, #D74286 91.75%);
  background-repeat:repeat-x;

  background-position: left 0% bottom 4.86svh,0px 0px;
}

/**
* estilos para el toolbar de el juego de parejas
*/
.toolbarParejas
{
  /*tiene dos background las neuronas y el degradado del fondo*/

  background:url('../../public/assets/componentes/Juegos/fondo_neuronas_morado_azul.svg'), 
    linear-gradient(309deg, #5755CA 5.69%, #4A86DF 88.19%);
  background-repeat:repeat-x;

  background-position: left 0% bottom 4.86svh,0px 0px;
}

/**
* estilos para el toolbar de el juego de calculo de fechas
*/
.toolbarCalculoFecha
{
  /*tiene dos background las neuronas y el degradado del fondo*/

  background:url('../../public/assets/componentes/Juegos/fondo_neuronas_violeta_rosado.svg'), 
  linear-gradient(125deg, #9F42BF -6.35%, #D74286 96.15%); 
  
  /* es para que si las pantallas son grandes se repita horizontalmente*/
  background-repeat:repeat-x;

  background-position: left 0% bottom 4.86svh,0px 0px;
}

/**
* estilos para el toolbar de el juego de neural trainer
*/
.toolbarNeuralTrainer
{
  /*tiene dos background las neuronas y el degradado del fondo*/

  background:url('../../public/assets/componentes/Juegos/fondo_neuronas_amarillo_rosado.svg'), 
    linear-gradient(161deg, #FFB21C 10.66%, #D74286 97.76%);
  
  /* es para que si las pantallas son grandes se repita horizontalmente*/
  background-repeat:repeat-x;
  
  /**
  * para cada background hay una posision
  * 4.86svh: para que las neuronas no inicien totalmente abajo,
  * 
  */
  background-position: left 0% bottom 4.86svh,0px 0px;
}

.toolbar_default{
  height: 10.96% ;
  justify-content: center;
  background: linear-gradient(137deg, var(--color-purple-dark) 20.06%, #D74286 219.38%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 4.3svh;
}
.contenedor_titulo_default{
  height: 25.83%;
  padding-top: 3.199svh;
}
.tituloJuegos{
  margin: 0;
  font-size: 2.84svh;
  font-weight: 700;
  line-height: 85% ;
  text-transform: uppercase;
  height: fit-content;  
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
}
.tituloEntVideo{
  margin-bottom: 25%;
  margin-top: 0;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 4.26vh;
}
/** *clase para el button back de los juegos
*/
.backJuegos{ 
  position: absolute;
  background: url(../../public/assets/icon/flechaBlancaBack.svg) center right/ contain;
  background-repeat: no-repeat;
  height: 2svh ;
  width: auto;
  aspect-ratio: 0.588;
  left: 5.12%;
  border: none;
}
.buttonCross{
  flex: 1;
  background: url(../../public/assets/icon/closeCircle.svg) center / contain;
  background-repeat: no-repeat;
  height: 4vh;
  width: 4vh;
  border: none;
}

.primeraPantalla{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* estilos para la pantalla 1 de cada juego */
.primeraPantalla{
  padding: 14.22svh 3.85% 0 3.85%;
  font-family: 'Montserrat';
  font-style: normal;
  height: 100%;
}

/**
* atributos en comun de todos los contenedores del logo del los juegos
*/
.contenedorIconoNombres, .contenedorIconoPalabras, .contenedorIconoSC,
.contenedorIconoNumeros, .contenedorIconoMatematico, .contenedorIconoParejas,
.contenedorIconoFecha, .contenedorIconoNeural
{
  height: 25.12svh; 
  width: 25.12svh;
  position: absolute;
  top: 23.22%;
  border-radius: 47.5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 13;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/**
* background del contenedor del icono del juego memoria nombres
*/
.contenedorIconoNombres{
  background: linear-gradient(144deg, #77B15C 9.63%, #4A86DF 151.57%);
  
}

/**
* background del contenedor del icono del juego memoria palabras
*/

.contenedorIconoPalabras{
  background: linear-gradient(315deg, #77B15C -91.73%, #4A86DF 100%);
}

/**
* background del contenedor del icono del juego SuperCerebros Dice
*/
.contenedorIconoSC{
  background:linear-gradient(144deg, #5755CA 6.94%, #D74286 198.95%);  ;
}

/**
* background del contenedor del icono del juego memoria numeros
*/
.contenedorIconoNumeros{
  background:linear-gradient(325deg, #4A86DF 5.25%, #5755CA 42.81%);
}

/**
* background del contenedor del icono del juego calculo matematico
*/
.contenedorIconoMatematico{
  background: linear-gradient(180deg, #EA6980 -77.7%, #D74286 100%);
}

/**
* background del contenedor del icono del juego Parejas
*/
.contenedorIconoParejas{
  background: linear-gradient(144deg, #4A86DF 3.06%, #5755CA 98.99%);
}

/**
* background del contenedor del icono del juego de calculo de la fecha
*/
.contenedorIconoFecha{
  background: linear-gradient(134deg, #9F42BF -8.28%, #D74286 113.41%);
}

/**
* background del contenedor del icono del juego de neural trainer
*/
.contenedorIconoNeural{
  background:  linear-gradient(137deg, #FFB21C 6.73%, #EA7951 94.02%);
}
.contenedorVideo{
  background-color: white;
  height: fit-content;
  overflow: hidden;
  width: 100%;
  z-index: 2000;
  margin: auto;
}
.icono{
  width: 18.25svh;
  height: 18.25svh;
}
.buttonEmpezar, .descripcionJuego, .rankingJuegos{
  font-style: normal;
  border: none
}
.descripcionJuego{
  text-align: center;
  padding: 0 10% 0 10%;
  font-size: 2.37vh;
  font-weight: 400;
  line-height: 2.72vh;
  color: #404040;
  margin-bottom: 2vh;
}

.buttonEmpezar{
  padding: 4%;
  background: #D74286;
  border-radius: 15px;
  width: 100%;
  height: fit-content;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 40px; 
  margin-bottom: 2.36svh;
  color: #ffffff;
}


/* clase que da los bordes al boton de ranking de los juegos*/

.BordesButtonRanking{
  border-radius: 15px;
  border: 2px solid #D74286;
  width: 100%;
  height: 17.14%;
  margin-bottom: 2vh;
}
/* clase del boton de ranking, 
  el boton esta dentro de una etiqueta link
  revisar clase: BordesButtonRanking
*/
.rankingJuegos{
  background: white;
  cursor: pointer;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 222.222%;
  border-radius: 15px;
  color: #D74286 ;
}
.degradadoTexto{
  background: linear-gradient(180deg, var(--color-purple-dark) 0%, #D74286 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  z-index: 3;
}


/* estas clases son de memoria numeros */
.itemDigitos{
  
  font-size: 2.37svh;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  display: flex;
  align-items: center; 
  justify-content: start;
  border-radius: 15px;
  border: 2px solid #C0BCC2;
  color: #868189;
  background: #F6F4F7;
  width: 88.88%;
  height: 61.85%;
  padding-left: 6.25%;
}
.contenedorDigitos{
  width: 100%;
  height: 27.71%;
  margin-bottom:2vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  font-style: normal;
  font-family: 'Montserrat';
  position: relative;
}
.selectDigitos{
  position: absolute;
  top: 0;
  right: 0;
  background: #F6F4F7;
  border-radius: 50%;
  border: 2px solid #C0BCC2;
  height: 100%;
  width: auto;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.selectDigitos div{
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  z-index: 100;
}

.selectDigitos div:nth-child(1){
  justify-content: start;
}

.selectDigitos h1{
  font-size: 7.58svh;
  font-weight: 900;
  line-height: 90%;
  text-transform: capitalize;
  font-family: 'Montserrat';
  margin: 0;
  user-select: none;
  position: inherit;
}

.degradadoDigitos{
  background: linear-gradient(172deg, #5954C8 5.83%, #4A86DF 202.38%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 20;
}
.descripcionMemoriaNumeros{
  margin-bottom: 0.8vh;
}

.circulos{
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin-top: 13vh;
  margin-bottom: 10vh;
  flex-direction: row;
  padding: 0;
}

.circulo {
  width: 1.3vh;
  height: 1.3vh;
  min-height: 11px;
  min-width: 11px;
  border-radius: 50%;
  
  margin: 0 5px;
  cursor: pointer;
}
.circuloActivado{
  background: #D74286;
}
.circuloDesactivado{
  background-color: #d9d9d9;
}

.buttonFinalizar{
  height: 4.7vh;
  width: 40% !important;
  --border-radius:15px;
}

.subtitulo_texto{
  color: var(--medium-grey, #868189);
  font-family: Source Sans Pro;
  font-size: 5svw;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  width: 100%;
  text-align: center;
  margin: 0%;
  margin-bottom: 5%;
}

.card_blocked{
  background: url(../../public/assets/icon/candado.png) center / 35% 50% no-repeat, 
  url(/public/assets/componentes/Juegos/calculo_fecha_recortado.svg) bottom right / 60% 60% no-repeat,
  #D9D9D9 !important;
  font-weight: 900 !important;
  opacity: 1 !important;
}
        
.card_blocked_backNeural{
  background: url(../../public/assets/icon/candado.png) center / 35% 50% no-repeat, 
  url(/public/assets/componentes/Juegos/NEURAL\ TRAINER.svg) bottom -100% right -100% / 80% 80% no-repeat,
  #D9D9D9 !important;
}

.card_blocked_backParejas{
  background: url(../../public/assets/icon/candado.png) center / 8svh 10svh no-repeat, 
  url(/public/assets/componentes/Juegos/parejas_recortado.svg) bottom right -116%/ 80% 80% no-repeat,
  #D9D9D9 !important;
  
}
/* Modulo para BotonesJuegos.tsx */

.div_botones_grid{
  display:grid;
  grid-template-columns:repeat(2,2fr);
  gap: 5%;
  margin-bottom:3%;
  width: 100%;
  height: fit-content;
  padding:0 5.95svh;
  column-gap: 3.35svh;
  row-gap: 3.65svh;
}

.div_botones_grid button:nth-child(n){
  max-height: 6svh;
  height: fit-content;
  border-radius: 15px;
  overflow: hidden;
  color: white;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-size: 1.87svh;
  font-family: 'Montserrat';
  font-weight: 600;
  line-height: 110%;
  text-transform: uppercase;
}

.div_botones_grid button:last-child:nth-child(odd){
  grid-column: 1 / span 2;
  margin: 0 25.4%;
  width: 50%;
}

.botonVolver{
  border-radius: 15px;
  border: none;
  background: linear-gradient(-10deg, #D74286 10%, var(--color-purple-dark) 100%);
  position: relative;
  width: 100%;
  padding: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.botonVolver div{
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 13px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.botonVolver span{
  width: fit-content;
  text-overflow: ellipsis;
}


.buttonPlayAgain{
  background-color: var(--color-Pink-Light);
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding: 5.55% 5%;
  height: 100% !important;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.botonRanking{
  /* correct */
  width: fit-content;
  background-color: var(--color-purple-dark);
  color: white !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 3.95%;

}

.botonRanking span{
  /* correct */
  width: fit-content;
  height: fit-content;
  text-overflow: ellipsis;
  overflow: hidden;
  color: white;
}

/* Modulo NavegacionImagen.tsx */
.navegationContainer{
  /* correct */
  position: relative;
  width: 100%;
  height: 32svh;
  display: flex;
  flex-direction: row;
  margin-bottom: 0%;
  align-items: center;
  justify-content: center;
}

.navegation{
  /* correct */
  position: absolute;
  width: 50%;
  height: 100%;
  background-size: 5%;
  background-repeat: no-repeat;
}

.navegation_left{
  /* correct */
  left: 0;
  background: url(../../public/assets/icon/pasarFlechaRosada.svg) center left 5.1% / 10% no-repeat;
}

.navegation_right{
  /* correct */
  right: 0;
  transform: rotate(180deg);
  background: url(../../public/assets/icon/pasarFlechaRosada.svg) center left 5.1% / 10% no-repeat;
}

@media screen and (min-aspect-ratio: 7/8) {
  .subtitulo_texto{
    font-size: xx-large;
  }
}