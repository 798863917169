/* /features/juegoFechas/styles/JuegoFechas.module.css */
.page {
    padding: 20px;
    text-align: center;
  }
  
  .inicio {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .startButton {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .pregunta {
    font-size: 2em;
  }
  
  .contenedorRespuestas {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .botonDia {
    width: 8em;
  }
  
  .resultado {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .botonReiniciar {
    padding: 10px 20px;
    background-color: #e74c3c;
    color: white;
    border: none;
    cursor: pointer;
  }

  .containButtons{
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
  }
  