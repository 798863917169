.contenedor{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #FFF8;
    z-index: 200;

    display: grid;
    align-items: center;
    justify-items: center;
    top: 0;
    left: 0;
}

.infoCartel{
    position: relative;

    transition: transform 0.3s ease-in-out;
}

.cartelOculto{
  transform: translateY(100vh);
}
